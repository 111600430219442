import { keyframes, styled } from "solid-styled-components";

const LoaderAnimation = keyframes`
    0% {
        background-position: 0% ;
    }
	
    100% {
        background-position: -400% ;
    }
`;
/**
 * Stateless component which renders animated div used as a replacement before the actual data has been loaded
 */
export const Template = styled.div<{
	w?: string;
	h?: string;
	opacity?: number;
	margin?: string;
	borderRadius?: string;
	maxW?: string;
}>`
  ${(props) => `
        width: ${props.w ?? "100%"};
        height: ${props.h ?? "50px"};
         ${props.maxW ? `max-width:${props.maxW};` : ""};
        background-color: ${props.theme?.loader?.dark};
        background: linear-gradient(90deg, 
			${props.theme?.loader?.light} 0%, 
			${props.theme?.loader?.dark} 50%, 
			${props.theme?.loader?.light} 100%);
        opacity: ${props.opacity ?? 1};
        ${props.margin ? `margin: ${props.margin};` : ""}
        ${props.borderRadius ? `border-radius: ${props.borderRadius};` : ""}
    `}
  background-size: 200%;
  animation: ${LoaderAnimation} ease 2s infinite;
  flex-shrink: 0;
`;
