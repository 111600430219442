import type { UserNotification } from "@api/notifications/types";

const content = (n: UserNotification) => {
	switch (n.type) {
		case "conversation.message.create":
			return (
				<>
					Uživatel <span>{n.triggeredBy?.nickname}</span> vám poslal zprávu
				</>
			);
		case "group_invite.create":
			return (
				<>
					Byly jste pozvání do <span>{n.ref.name}</span>
				</>
			);
		case "group_member.kick":
			return (
				<>
					Byly jste odstraněni z <span>{n.ref.name}</span>
				</>
			);
		case "group_member.leave":
			return (
				<>
					<span>{n.triggeredBy?.nickname}</span> opustil <span>{n.ref.name}</span>
				</>
			);
		case "tracklist.create":
			return (
				<>
					Uživatel <span>{n.triggeredBy?.nickname}</span> vytvořil program <span>{n.ref.name}</span>
				</>
			);
		case "tracklist_comment.create":
			return (
				<>
					Uživatel <span>{n.triggeredBy?.nickname}</span> vytvořil komentář u programu
					<span>{n.ref.name}</span>
				</>
			);
		case "tracklist_like.create":
			return (
				<>
					<>
						Uživatel <span>{n.triggeredBy?.nickname}</span> dal like programu
						<span>{n.ref.name}</span>
					</>
				</>
			);
		case "user_follow.create":
			return (
				<>
					Uživatel <span>{n.triggeredBy?.nickname}</span> vás začal sledovat
				</>
			);
	}
};
export default content;
