import {
	type ChainedTranslator,
	type Translator,
	chainedTranslator,
	flatten,
	translator,
} from "@solid-primitives/i18n";
import { setToStorage } from "@src/app/utils";
import { type AvailableLanguage, dictionaries } from "@trans/index";
import {
	type Accessor,
	type ParentComponent,
	type Setter,
	createContext,
	createEffect,
	createResource,
	createSignal,
	on,
} from "solid-js";
import type { Dictionary } from "./types";
import { fetchDictionary, getUserLocale } from "./utils";

const [locale, setLocale] = createSignal(getUserLocale());

export const i18nContext = createContext<{
	t: Translator<Dictionary> | undefined;
	locale: Accessor<AvailableLanguage>;
	setLocale: Setter<AvailableLanguage>;
	chained: ChainedTranslator<typeof dictionaries.en> | undefined;
}>({
	t: undefined,
	locale,
	setLocale,
	chained: undefined,
});

const I18nProvider: ParentComponent = (props) => {
	const [dictionary] = createResource(locale, fetchDictionary, { initialValue: flatten(dictionaries.en) });

	const t = translator(dictionary);
	const chained = chainedTranslator(dictionaries.en, t);

	createEffect(on(locale, (l) => setToStorage(l, "lng", "user")));

	return <i18nContext.Provider value={{ t, locale, setLocale, chained }}>{props.children}</i18nContext.Provider>;
};
export default I18nProvider;
