import type { z } from "zod";
import type { groupRoleSchema, groupSchema, groupUser } from "./schemas";

export type Group = z.infer<typeof groupSchema>;
export type GroupRole = z.infer<typeof groupRoleSchema>;
export type GroupUser = z.infer<typeof groupUser>;

export enum GroupPermissions {
	TRACKLIST_CREATE = "tracklist.create",
	TRACKLIST_DELETE = "tracklist.delete",
	MEMBER_INVITE_CREATE = "member_invite.create",
	MEMBER_DELETE = "member.delete",
	GROUP_METADATA_UPDATE = "group.metadata.update",
}
