import type { SoundEffect } from "@views/App/Create/components/TrackEditor/hooks/useSoundEffects/types";

const effects: Record<SoundEffect, string> = {
	nature: "Nature",
	ocean: "Ocean",
	smile: "Smile",
	thumbs_down: "Thumbs Down",
	trumpet: "Trumpet",
};
export default effects;
