import type globalEn from "../en/global";
const global: typeof globalEn = {
	or: "nebo",
	error: "Něco se pokazilo",
	loading: {
		loading: "Načítání",
		success: "Načteno",
		error: "Načítání selhalo",
	},
	seeAll: "zobrazit vše",
	delete: "smazat",
	close: "zavřít",
	edit: "upravit",
	copied: "zkopírováno do schránky",
	playAll: "přehrát vše",
	saving: {
		loading: "ukládání",
		success: "uloženo",
		error: "ukládání se nezdařilo",
	},
	deleting: {
		loading: "probíhá mazání",
		success: "smazáno",
		error: "mazání se nezdařilo",
	},
	uploading: {
		loading: "probíhá nahrávání",
		success: "nahráno",
		error: "nahrávání se nezdařilo",
	},
	download: "stáhnout",
	continue: "pokračovat",
	cancel: "zrušit",
	appDownload: {
		header: "stáhněte si voxtox aplikaci",
		sub: "užívejte si hudbu, podkásty a mnohem více",
	},
	cookies: {
		text: [
			"Tyto webové stránky používají a sdílejí informace o koncových uživatelích, včetně jedinečných identifikátorů (jako jsou soubory cookie, IP adresy a e-maily), které umožňují identifikaci uživatele.",
			"Používáním této stránky potvrzujete svůj souhlas s přístupem a zpracováním výše uvedených osobních údajů a jejich případným sdílením s našimi vybranými partnery (včetně analytických nástrojů).",
		],
		allow: "Souhlasím",
		more: "zjistit více",
	},
	yes: "ano",
	no: "ne",
	link: "odkaz",
};
export default global;
