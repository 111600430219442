import ErrorOverlay from "@components/ErrorOverlay";
import { type Component, ErrorBoundary } from "solid-js";
import App from "./app";
import I18nProvider from "./app/contexts/I18nProvider";
import { ThemeProvider } from "./app/contexts/Theme";
import "./app/styles/reboot.css";
const Entry: Component = () => {
	return (
		<ThemeProvider>
			<I18nProvider>
				<ErrorBoundary fallback={(e) => <ErrorOverlay error={String(e)} />}>
					<App />
				</ErrorBoundary>
			</I18nProvider>
		</ThemeProvider>
	);
};

export default Entry;
