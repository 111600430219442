import type { Track } from "@api/tracks/types";

export const emojiLanguage = {
	en: "🇬🇧",
	de: "🇩🇪",
	fr: "🇫🇷",
	es: "🇪🇸",
	it: "🇮🇹",
	pl: "🇵🇱",
	uk: "🇺🇦",
	cs: "🇨🇿",
	ja: "🇯🇵",
};
// const emojisArray = Object.values(emojiLanguage);

// /**
//  * Removes specified emojis from a string
//  */
// const removeEmojis = (inputString: string, emojisToRemove: string[]) => {
//   const emojiPattern = emojisToRemove
//     .map((emoji) => {
//       // Escape any special characters in the emoji for the regex pattern
//       return emoji.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
//     })
//     .join('|');

//   const regex = new RegExp(emojiPattern, 'g');
//   const stringWithoutEmojis = inputString.replace(regex, '');

//   return stringWithoutEmojis;
// };

/**
 * Filters out track which do not contain a specific emoji tied to a language.
 * Filtering only happens if at least one track has user's preferred language.
 */
export const filterTracks = (tracks: Track[]) => {
	return tracks;
	// const langs = navigator.languages;
	// let myLanguages: string[] | undefined = undefined;
	// const emojis = Object.entries(emojiLanguage)
	//   .filter((entry) => {
	//     if (langs.some((l) => l.includes(entry[0]))) {
	//       if (!myLanguages) myLanguages = [];
	//       myLanguages.push(entry[1]);
	//       return false;
	//     }
	//     return true;
	//   })
	//   .map((entry) => entry[1]);

	// if (!myLanguages) return tracks;

	// return tracks.some((tr) => myLanguages?.some((l) => tr.name.includes(l)))
	//   ? tracks
	//       .filter((tr) => !emojis.some((e) => tr.name.includes(e)))
	//       .map((tr) => ({
	//         ...tr,
	//         name: removeEmojis(tr.name, emojisArray)
	//       }))
	//   : tracks;
};
