import Button from "@components/Button";
import FadedText from "@components/FadedText";
import type { JSX } from "solid-js";
import { css, styled } from "solid-styled-components";

export const Row = styled.div<{
	alignItems?: JSX.CSSProperties["align-items"];
	justifyContent?: JSX.CSSProperties["justify-content"];
	margin?: JSX.CSSProperties["margin"];
	h?: JSX.CSSProperties["height"];
	w?: JSX.CSSProperties["width"];
	padding?: JSX.CSSProperties["padding"];
	flexWrap?: JSX.CSSProperties["flex-wrap"];
	flexShrink?: JSX.CSSProperties["flex-shrink"];
	gap?: JSX.CSSProperties["gap"];
	maxWidth?: JSX.CSSProperties["max-width"];
	minWidth?: JSX.CSSProperties["min-width"];
}>`
  display: flex;
  flex-direction: row;
  ${(props) => `
		width: ${props.w === undefined ? "100%" : props.w};
		${props.h === undefined ? "" : `height: ${props.h};`};
		${props.margin === undefined ? "" : `margin: ${props.margin};`};
		${props.padding === undefined ? "" : `padding: ${props.padding};`};
		${props.flexWrap === undefined ? "" : `flex-wrap: ${props.flexWrap};`};
		${props.flexShrink === undefined ? "" : `flex-shrink: ${props.flexShrink};`};
		${props.alignItems === undefined ? "" : `align-items: ${props.alignItems};`};
		${props.justifyContent === undefined ? "" : `justify-content: ${props.justifyContent};`};
		${props.gap === undefined ? "" : `gap: ${props.gap};`};
		${props.maxWidth === undefined ? "" : `max-width: ${props.maxWidth};`};
		${props.minWidth === undefined ? "" : `min-width: ${props.minWidth};`};
	`}
`;
export const Column = styled.div<{
	alignItems?: JSX.CSSProperties["align-items"];
	justifyContent?: JSX.CSSProperties["justify-content"];
	margin?: JSX.CSSProperties["margin"];
	padding?: JSX.CSSProperties["padding"];
	w?: JSX.CSSProperties["width"];
	h?: JSX.CSSProperties["height"];
	flexGrow?: JSX.CSSProperties["flex-grow"];
	flexShrink?: JSX.CSSProperties["flex-shrink"];
	gap?: JSX.CSSProperties["gap"];
	maxWidth?: JSX.CSSProperties["max-width"];
	minWidth?: JSX.CSSProperties["min-width"];
	minH?: JSX.CSSProperties["min-height"];
	position?: JSX.CSSProperties["position"];
}>`
  display: flex;
  flex-direction: column;
  ${(props) => `
		width: ${props.w === undefined ? "100%" : props.w};
		${props.h === undefined ? "" : `height: ${props.h};`};
		${props.margin === undefined ? "" : `margin: ${props.margin};`};
		${props.padding === undefined ? "" : `padding: ${props.padding};`};
		${props.flexShrink === undefined ? "" : `flex-shrink: ${props.flexShrink};`};
		${props.flexGrow === undefined ? "" : `flex-grow: ${props.flexGrow};`};
		${props.alignItems === undefined ? "" : `align-items: ${props.alignItems};`};
		${props.justifyContent === undefined ? "" : `justify-content: ${props.justifyContent};`};
		${props.gap === undefined ? "" : `gap: ${props.gap};`};
		${props.maxWidth === undefined ? "" : `max-width: ${props.maxWidth};`};
		${props.minWidth === undefined ? "" : `min-width: ${props.minWidth};`};
		${props.minH === undefined ? "" : `min-height: ${props.minH};`};
		${props.position === undefined ? "" : `position: ${props.position};`};
	`}
`;

export const Card = styled(Column)<{
	borderRadius?: JSX.CSSProperties["border-radius"];
}>`
  ${({ theme, borderRadius }) => `
    background: ${theme?.colors.foreground};
    border-radius: ${borderRadius ?? "16px"};
  `};
`;

export const AltText = styled(FadedText)`
  color: ${({ theme }) => theme?.colors.accentPrimary} !important;
`;

export const LineClamp = css`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: normal;
  word-break: break-all;
`;

export const FollowButton = styled(Button)<{ followed?: boolean; live?: boolean }>`
  flex-shrink: 0;
  transition: all 300ms ease-in-out !important;
  ${({ live, theme }) => (live ? `box-shadow: 0 0 10px 2px ${theme?.colors.error};` : "")}
  .unfollow {
    display: none;
  }

  &:hover {
    ${({ followed, theme }) =>
			followed
				? `background: ${theme?.colors.error}; 
        color: white;
        .unfollow {
          display: block;
        }`
				: ""}
    .following {
      display: none;
    }
    .follow {
      display: none;
    }
  }
`;
