import { appThemes } from "@app/styles/themes";
import { getFromStorage, setToStorage } from "@app/utils";
import { type Accessor, type ParentComponent, createContext, createRenderEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import type { DefaultTheme } from "solid-styled-components";
import { ThemeProvider as StyledThemeProvider } from "solid-styled-components";
import { type Theme, themes } from "./types";

export const themeContext = createContext<{
	theme: Accessor<Theme>;
	setTheme: (theme: Theme) => void;
}>({
	theme: () => themes[0],
	setTheme: () => {},
});

export const ThemeProvider: ParentComponent = (props) => {
	const [theme, setTheme] = createSignal<Theme>("lightTheme");
	const [activeTheme, setActiveTheme] = createStore<DefaultTheme>(structuredClone(appThemes.lightTheme));

	const isTheme = (t: string): t is Theme => themes.includes(t as Theme);

	const setThemeName = (themeName: Theme) => {
		setTheme(isTheme(themeName) ? themeName : "lightTheme");
		setActiveTheme(() => {
			if (themeName === "lightTheme") return { ...appThemes.lightTheme };
			return { ...appThemes.darkTheme };
		});
		setToStorage(themeName, "theme", "user");
	};

	createRenderEffect(() => {
		window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
			const themeName = e.matches ? "darkTheme" : "lightTheme";
			setTheme(themeName);
			setToStorage(themeName, "theme", "user");
			setActiveTheme(structuredClone(themeName === "lightTheme" ? appThemes.lightTheme : appThemes.darkTheme));
		});

		const themeName = (getFromStorage("theme", "user") ?? "lightTheme") as Theme;
		setThemeName(themeName);
	});

	const handleChangeTheme = (newTheme: Theme) => {
		setThemeName(newTheme);
	};

	return (
		<themeContext.Provider value={{ theme, setTheme: handleChangeTheme }}>
			<StyledThemeProvider theme={activeTheme}>{props.children}</StyledThemeProvider>
		</themeContext.Provider>
	);
};
