import { Show, splitProps } from "solid-js";
import { ButtonBase } from "./styled";
import type { ButtonProps } from "./types";

const Button = (props: ButtonProps) => {
	const [local, others] = splitProps(props, ["iconPosition", "icon", "children"]);

	return (
		<ButtonBase {...others}>
			<Show when={(local.iconPosition === "left" || !local.iconPosition) && local.icon}>
				<div>{props.icon}</div>
			</Show>
			{local.children}
			<Show when={local.iconPosition === "right" && local.icon}>
				<div>{props.icon}</div>
			</Show>
		</ButtonBase>
	);
};
export default Button;
