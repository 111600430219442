import type { JSX } from "solid-js";
import { styled } from "solid-styled-components";

const FadedText = styled.p<{
	fontSize?: JSX.CSSProperties["font-size"];
	lineHeight?: JSX.CSSProperties["line-height"];
	noWrap?: boolean;
	fontWeight?: JSX.CSSProperties["font-weight"];
	whiteSpace?: JSX.CSSProperties["white-space"];
	textAlign?: JSX.CSSProperties["text-align-last"];
	padding?: JSX.CSSProperties["padding"];
}>`
  ${({ fontSize }) => (fontSize === undefined ? "" : `font-size: ${fontSize};`)};
  line-height: ${({ lineHeight }) => lineHeight ?? "1.15"};
  color: ${({ theme }) => theme?.colors.accentTertiary};
  ${({ fontWeight }) => (fontWeight === undefined ? "" : `font-weight: ${fontWeight};`)};
  ${({ whiteSpace }) => (whiteSpace === undefined ? "" : `white-space: ${whiteSpace};`)};
  ${({ textAlign }) => (textAlign === undefined ? "" : `text-align: ${textAlign};`)};
  ${({ padding }) => (padding ? `padding: ${padding};` : "")};
`;

export default FadedText;
