import type { SoundEffect } from "@views/App/Create/components/TrackEditor/hooks/useSoundEffects/types";

const effects: Record<SoundEffect, string> = {
	nature: "Příroda",
	ocean: "Oceán",
	smile: "Smích",
	thumbs_down: "Place dolů",
	trumpet: "Trumpeta",
};
export default effects;
