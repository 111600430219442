import * as Sentry from "@sentry/browser";
import { render } from "solid-js/web";
import Entry from "./Entry";
import { ENV } from "./env";

if (ENV.VITE_DSN)
	Sentry.init({
		dsn: ENV.VITE_DSN,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		tracesSampleRate: 0.2,
		environment: import.meta.env.MODE,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

render(() => <Entry />, document.getElementById("root") as HTMLElement);
