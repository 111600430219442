import { fetchResource } from "@app/utils/";
import { z } from "zod";
import { userNotificationsHistorySchema } from "./schemas";

/**
 * post device token
 * @param token device token, unique to a device not a used
 */
export const postNotificationToken = (token: string) =>
	fetchResource(
		"user/notification",
		z.object({}),
		"POST",
		{ token },
		{
			emptyResponse: true,
		},
	);

/**
 * Delete device token, token should be deleted after user logs out, otherwise the device is still going to receive notifications
 * @param token device token, unique to a device not a used
 */
export const deleteNotificationToken = (token: string) =>
	fetchResource("user/notification", z.object({}), "DELETE", { token }, { emptyResponse: true });

/**
 * Get current users notification history
 */
export const getNotificationsHistory = (page = 1) =>
	fetchResource(`user/notification/history?page=${page}`, userNotificationsHistorySchema);

/**
 * Read all user's notifications
 */
export const postReadNotifications = () =>
	fetchResource("user/notification/history", z.object({}), "POST", undefined, { emptyResponse: true });

/**
 * Read specific notification
 */
export const postReadNotification = (id: number) =>
	fetchResource(`user/notification/history/${id}`, z.object({}), "POST", undefined, { emptyResponse: true });
