import appleStore from "@assets/icons/stores/appleStore.png";
import googlePlay from "@assets/icons/stores/googlePlay.png";
import FadedText from "@components/FadedText";
import { Column, Row } from "@components/Layout";
import { Template } from "@components/Template";
import useI18n from "@contexts/I18nProvider/hooks/useI18n";
import { Image } from "@kobalte/core";
import { getFromStorage, setToStorage } from "@src/app/utils";
import { FaSolidXmark } from "solid-icons/fa";
import { createSignal, onMount } from "solid-js";
import { Show } from "solid-js";
import UAParser from "ua-parser-js";
import { CardWrapper, StoreImage, VoxtoxLogo, WrappingRow } from "./styled";

const DownloadApp = () => {
	const [show, setShow] = createSignal(false);
	const [system, setSystem] = createSignal<string>();
	const { t } = useI18n();

	onMount(() => {
		if (getFromStorage("mobileHide", "user") === "hide") return;
		const ua = new UAParser(navigator.userAgent);
		const s = ua.getResult().os.name;
		setShow(s === "Android" || s === "iOS");
		setSystem(s);
	});

	const handleClose = () => {
		setToStorage("hide", "mobileHide", "user");
		setShow(false);
	};

	return (
		<Show when={show()}>
			<CardWrapper padding="5px">
				<WrappingRow alignItems="center" justifyContent="space-between" gap="5px">
					<Row w="max-content" alignItems="center" style={{ gap: "10px" }}>
						<FaSolidXmark font-size="30px" onClick={handleClose} style={{ cursor: "pointer" }} />
						<Column w="max-content" alignItems="center">
							<VoxtoxLogo src={"/logo_no_text.png"} />
							<FadedText fontSize="10px">voxtox</FadedText>
						</Column>
						<Column w="max-content">
							<h6>{t("global.appDownload.header")}</h6>
							<FadedText fontSize="10px">{t("global.appDownload.sub")}</FadedText>
						</Column>
					</Row>
					{system() === "Android" && (
						<a href={import.meta.env.VITE_GOOGLE_PLAY} target="_blank" rel="noreferrer">
							<StoreImage>
								<Image.Img src={googlePlay} />
								<Image.Fallback>
									<Template h="30px" w="150px" />
								</Image.Fallback>
							</StoreImage>
						</a>
					)}

					{system() === "iOS" && (
						<a href={import.meta.env.VITE_APP_STORE} target="_blank" rel="noreferrer">
							<StoreImage>
								<Image.Img src={appleStore} />
								<Image.Fallback>
									<Template h="30px" w="150px" />
								</Image.Fallback>
							</StoreImage>
						</a>
					)}
				</WrappingRow>
			</CardWrapper>
		</Show>
	);
};
export default DownloadApp;
