const docs = {
	errorCode: (name: string) => (
		<>
			Could not generate code for <strong>{name}</strong>
		</>
	),
	print: "print",
	source: "download source",
};
export default docs;
