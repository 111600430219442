import { Toast } from "@kobalte/core";
import { styled } from "solid-styled-components";
import type { ToastType } from "./types";

export const ToasterList = styled(Toast.List)`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
  width: 400px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 9999;
  outline: none;
`;

export const ToastWrapper = styled(Toast.Root)<{ toastType: ToastType }>`
  border-radius: 10px;
  padding: 15px;
  ${({ theme, toastType }) => `
    color: ${theme?.colors.colorInvert};
    background: ${
			toastType === "default"
				? theme?.colors.accentPrimary
				: toastType === "error"
					? theme?.colors.error
					: theme?.colors.success
		};
  `}
`;
