import type embedEn from "@trans/en/embed";

const embed: typeof embedEn = {
	open: "Otevřít voxtox",
	listen: "Poslouchejte na voxtox",
	join: "Připojte se do naší komunity na voxtox.fm a vytvořte svou vlastní!",
	joinSub: "Mluvené slovo, hudba, podkásty, radio a mnohem více...:)",
	joinSubAlt: "v jedné audio aplikaci ZDARMA navždy!",
	down: "Stáhnout aplikaci",
};
export default embed;
