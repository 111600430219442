import { getPreferences } from "@api/preferences";
import type { Tracklist } from "@api/tracklists/types";
import useI18n from "@contexts/I18nProvider/hooks/useI18n";
import { type ParentComponent, createContext, createEffect, createSignal, on } from "solid-js";

const [preferences, setPreferences] = createSignal<{ text: string; id: number }[]>([]);

const getCategories = (cats: Tracklist["categories"]) => {
	const categories: string[] = [];

	for (const c of cats) categories.push(preferences().find((p) => p.id === c.id)?.text ?? "");

	return categories.sort((a, b) => a.localeCompare(b));
};

export const preferencesContext = createContext({ preferences, getCategories });

export const PreferencesProvider: ParentComponent = (props) => {
	const { locale } = useI18n();

	createEffect(
		on(locale, () => {
			getPreferences(locale())
				.then((res) =>
					Object.values(res)
						.flat()
						.map((v) => ({ id: v.id, text: v.text })),
				)
				.then(setPreferences);
		}),
	);
	return (
		<preferencesContext.Provider value={{ preferences, getCategories }}>{props.children}</preferencesContext.Provider>
	);
};
