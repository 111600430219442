import { Card, Row } from "@components/Layout";
import { Image } from "@kobalte/core";
import { styled } from "solid-styled-components";

export const CardWrapper = styled(Card)`
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100dvw;
  background: ${({ theme }) => theme?.downloadApp};
  border-radius: 0;
  margin: 0;
`;

export const VoxtoxLogo = styled.img`
  width: 40px;
  border-radius: 15px;
  margin-bottom: 5px;
`;

export const WrappingRow = styled(Row)`
  @media screen and (max-width: 350px) {
    flex-wrap: wrap;
    a {
      margin: 0 auto;
    }
  }
`;

export const StoreImage = styled(Image.Root)`
  height: 30px;
  display: block;
  & > * {
    height: 100%;
  }
`;
