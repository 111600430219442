import { z } from "zod";

export const userSchema = z.object({
	uid: z.string(),
	nickname: z.string().min(3).max(100),
	photo: z.string().url().catch("/logo_no_text.png"),
	followers: z.number().int().nonnegative(),
	followed: z.boolean(),
	background: z.string().url(),
	activity: z.number().int().nonnegative(),
	private: z.boolean(),
	blocked: z.boolean(),
	following: z.number().int().nonnegative().catch(0),
	tracklists: z.number().int().nonnegative(),
});

export const oldUserSchema = userSchema
	.omit({
		tracklists: true,
		following: true,
		followers: true,
	})
	.extend({
		follows: z.number().int().nonnegative(),
	})
	.transform((o) => ({ ...o, tracklists: 0, following: 0, followers: o.follows, follows: undefined }))
	.pipe(userSchema);

export const artworkSchema = z.object({
	id: z.string(),
	url: z.string().url(),
	sizes: z.array(z.string()).nullish(),
});

export enum Genders {
	male = "male",
	female = "female",
	other = "other",
}

export const userCategorySchema = z.object({
	id: z.number(),
	name: z.string(),
});

export const userTagsSchema = z.object({
	languages: z.string().array(),
	category: userCategorySchema.nullable(),
	interests_in: z.string().nullable(),
	inspired_by: z.string().nullable(),
	birthday: z.string().nullable(),
	work_as: z.string().nullable(),
	city: z.string().nullable(),
	gender: z.nativeEnum(Genders).nullable(),
});

export const userBioSchema = z.object({
	content: z.string(),
	audio: z
		.object({
			url: z.string(),
			length: z.number().nullable(),
		})
		.nullable(),
});
