const createHelp = [
	{
		label: "přidej hudbu",
		header: "Přidejte do programu svoji oblíbenou hudbu",
		points: [
			() => (
				<>
					Do vyhledávacího pole <strong>napište&nbsp;název písničky, nebo interpreta</strong> a voxtox vám
					v&nbsp;katalogu vyhledá skladby, které má k&nbsp;dispozici.
				</>
			),
			() => (
				<>
					Z těchto skladeb jednoduše <strong>vyberete tlačítkem +</strong>, můžete vybrat i několik skladeb najednou.
				</>
			),
			() => (
				<>
					Výběr <strong>dokončíte tlačítkem ULOŽIT</strong> a vybraná hudba se přidá do programu. Katalog voxtox sice
					neobsahuje úplně všechno, ale je vám k dispozici <strong>přes 4 miliony skladeb.</strong>
				</>
			),
		],
	},
	{
		label: "přidej z voxtoxu",
		header: "Hledejte obsah na voxtoxu",
		points: [
			() => (
				<>
					Něco vás zaujalo na voxtoxu a chcete to mít ve svém programu? Do vyhledávacího pole{" "}
					<strong>zadejte jméno voxera, programu či konkrétní stopy.</strong>
				</>
			),
			() => (
				<>
					Z těchto nalezených stop jednoduše <strong>vyberete tu svoji tlačítkem +</strong>, můžete vybrat i několik
					stop najednou.
				</>
			),
			() => (
				<>
					Výběr <strong>dokončíte tlačítkem ULOŽIT</strong> a vybrané stopy se přidají do programu.
				</>
			),
		],
	},
	{
		label: "vlož soubor",
		header: "Vložte zvukový soubor",
		points: [
			() => (
				<>
					Máte vlastní nahrávku rozhovoru, podcastu či jakéhokoli jiného zvuku?{" "}
					<strong>Uploadujte si ji jako soubor</strong> přímo z&nbsp;vašeho počítače. Podporujeme soubory&nbsp;
					<strong>MP3,&nbsp;WAV&nbsp;a další. </strong>
				</>
			),
			() => (
				<>
					Vyberte zvukový soubor ve vašem zařízení, uploadujte na voxtox, můžete změnit jeho název, přidat k&nbsp;němu
					fotografii jako cover a <strong>pomocí tlačítka&nbsp;ULOŽIT&nbsp;jej nahrát do programu.</strong>
				</>
			),
			() => (
				<>
					K&nbsp;souborům s obsahem třetích stran <strong>musíte mít vyřešená práva.</strong>
				</>
			),
		],
	},
	{
		label: "napiš text",
		header: "Převeďte text na zvuk",
		points: [
			() => (
				<>
					Máte obsah, který je <strong>napsaný v textu, a chcete ho převést do zvukové stopy?</strong> Vyberte si
					mluvčí&nbsp;či mluvčího&nbsp;, případně dialog&nbsp;, pokud do zvuku převádíte například rozhovor dvou osob.
				</>
			),
			() => (
				<>
					Do textového pole zadejte text,<strong> v&nbsp;textu nenechávejte prázdné odstavce či znaky</strong>, které by
					mohly robotické hlasy mást.
				</>
			),
			() => (
				<>
					Stopu pojmenujte a přidejte k&nbsp;ní fotografii jako cover. Pomocí tlačítka <strong>ULOŽIT</strong> ji
					přidejte do programu.
				</>
			),
		],
	},
	{
		label: "nahraj audio",
		header: "Nahrávejte přímo do stopy",
		points: [
			() => (
				<>
					<strong>Klikněte na mikrofon</strong> a voxtox začne rovnou nahrávat. Nahrávání ukončíte kliknutím na tlačítko
					„stop“
				</>
			),
			() => (
				<>
					Tlačítkem play u stopy, jež se vám objeví pod mikrofonem, <strong>zkontrolujete svoji nahrávku.</strong> Pak
					jí už jen dáte název a pomocí tlačítka <strong>ULOŽIT</strong> přidáte do programu.
				</>
			),
			() => (
				<>
					U vaší nahrávky je <strong>primárně nastavena fotka z vašeho účtu.</strong> Můžete ji změnit po rozkliknutí
					editovacího kolečka&nbsp;u vaší nahrávky.
				</>
			),
		],
	},
];

export default createHelp;
