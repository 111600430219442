import { Toast } from "@kobalte/core";
import { Portal } from "solid-js/web";
import { ToasterList } from "./styled";
import { toast } from "./toast";

const Toaster = () => {
	return (
		<Portal>
			<Toast.Region>
				<ToasterList />
			</Toast.Region>
		</Portal>
	);
};
export { Toaster as default, toast };
