import { Button } from "@kobalte/core";
import type { JSX } from "solid-js";
import { styled } from "solid-styled-components";
import type { ButtonType } from "./types";

export const ButtonDefaultCss = `
  border: none;
  line-height: 0px;
  height: 56px;
  white-space: nowrap;
  display:flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 756px) {
    font-size: 14px;
  }
  gap: 10px;
`;

export const ButtonBase = styled(Button.Root)<{
	margin?: string;
	buttonType?: ButtonType;
	w?: JSX.CSSProperties["width"];
	h?: JSX.CSSProperties["height"];
	diameter?: JSX.CSSProperties["width"];
	fontSize?: JSX.CSSProperties["font-size"];
	fontWeight?: JSX.CSSProperties["font-weight"];
	padding?: JSX.CSSProperties["padding"];
	borderRadius?: JSX.CSSProperties["border-radius"];
}>`
  ${ButtonDefaultCss}
  ${(props) => `
    background: ${
			props.buttonType
				? props.buttonType === "error"
					? props.theme?.colors.error
					: props.buttonType === "outline" || props.buttonType === "outline_alt"
						? "transparent"
						: props.buttonType === "alt"
							? props.theme?.colors.foreground
							: props.theme?.button?.background
				: props.theme?.button?.background
		};
    
    padding: ${props.padding ?? "0 20px"};
    ${
			props.diameter
				? `
        width: ${props.diameter};
        height: ${props.diameter};
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        min-width: 0;
        padding: 0;
        `
				: `
        ${props.w ? `width: ${props.w};` : ""}
        ${props.h ? `height: ${props.h};` : ""}
        border-radius: ${props.borderRadius ?? "28px"};
         `
		}
    color: ${
			props.buttonType === "alt"
				? props.theme?.colors.accentPrimary
				: props.buttonType === "outline"
					? props.theme?.colors.accentTertiary
					: props.buttonType === "outline_alt"
						? props.theme?.colors
						: props.theme?.button?.color
		};
    ${props.margin ? `margin: ${props.margin};` : ""}
    font-weight: ${props.fontWeight ?? "bold"};
    font-size: ${props.fontSize ?? "20px"};
    ${
			props.buttonType === "outline" || props.buttonType === "outline_alt"
				? `border: 1px solid ${
						props.buttonType === "outline" ? props.theme?.colors.accentTertiary : props.theme?.colors.color
					};`
				: ""
		}
    
  `}
  cursor: pointer;
  flex-shrink: 0;
  transition: opacity 0.3s;
  &:disabled {
    filter: saturate(0);
    cursor: not-allowed;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
