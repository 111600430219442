import { rawTrackSchema, trackSchema } from "@api/tracks/schemas";
import { userSchema } from "@api/user/schemas";
import { z } from "zod";

const tracklistLocationSchema = z.object({
	id: z.number(),
	coordinates: z.object({
		latitude: z.number(),
		longitude: z.number(),
	}),
});
const tracklistCategory = z.object({
	id: z.number(),
	name: z.string(),
});

const tracklistSavedState = z.object({
	time: z.number(),
	track: z.lazy(() => rawTrackSchema.pick({ id: true, platform: true })),
});
const tracklistShare = z.object({
	uuid: z.string().uuid(),
	by: z.lazy(() => userSchema.pick({ uid: true, nickname: true, photo: true })),
	created_at: z.coerce.date(),
});

const tracklistGroup = z.object({
	name: z.string(),
	private: z.coerce.boolean(),
	uuid: z.string().uuid(),
});

export const tracklistSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	author: z.lazy(() => userSchema.pick({ uid: true, nickname: true, photo: true })),
	tracks: z.array(z.lazy(() => trackSchema)),
	rating: z.number().int(),
	comments: z.number().int(),
	group: tracklistGroup.nullable(),
	length: z.number().nonnegative().catch(0),
	location: tracklistLocationSchema.optional().nullable(),
	liked: z.boolean(),
	photo: z.string().url(),
	created_at: z.coerce.date(),
	bookmarked: z.boolean(),
	commented: z.boolean(),
	published: z.boolean(),
	play_count: z.number().int().nonnegative(),
	categories: z.array(tracklistCategory),
	saved_state: tracklistSavedState.nullable(),
	bookmark_count: z.number().int().nonnegative(),
	share: tracklistShare.nullable().optional(),
	publish_at: z.preprocess((v) => (v ? new Date(String(v)) : undefined), z.date().optional()),
});

export const tracklistArtworkSchema = z.object({
	id: z.string(),
	url: z.string().url(),
});
