import { tracklistSchema } from "@api/tracklists/schemas";
import { filterTracks } from "@api/tracklists/utils";
import { oldUserSchema } from "@api/user/schemas";
import type { User } from "@api/user/types";
import { cache } from "@solidjs/router";
import { fetchResource } from "@src/app/utils";
import type { addGroupSchema } from "@views/App/Groups/components/AddGroup/schemas";
import { z } from "zod";
import { groupRoleSchema, groupSchema, groupUser } from "./schemas";
import type { Group, GroupUser } from "./types";
import { fixGroupLinks } from "./utils";

/**
 * Create new group
 */
export const postGroup = (data: z.infer<typeof addGroupSchema>) => {
	const links = fixGroupLinks(data.links);

	return fetchResource("group", groupSchema.pick({ uuid: true, name: true }), "POST", { ...data, links });
};

/**
 * Fetch group by its id
 */
export const getGroup = cache((uuid: Group["uuid"]) => fetchResource(`group/${uuid}`, groupSchema), "group");

/**
 * fetch all user's group invites
 * @returns returns all groups user is invited to
 */
export const getGroupInvites = (page: number) => fetchResource(`user/group/invite?page=${page}`, z.array(groupSchema));

/**
 * accepts group's invite
 */
export const postAcceptGroupInvite = (uuid: Group["uuid"]) =>
	fetchResource(`user/group/${uuid}/invite`, z.object({}), "POST", undefined, { emptyResponse: true });

/**
 * delete group's invite
 */
export const deleteGroupInvite = (uuid: Group["uuid"]) =>
	fetchResource(`user/group/${uuid}/invite`, z.object({}), "DELETE", undefined, { emptyResponse: true });

/**
 * Invite new user to a group
 * @param uuid uuid of the group we want to invite the user to
 * @param userUIDs UID of the user we want to invite
 */
export const postInvites = (uuid: Group["uuid"], invites: { user_ids: User["uid"][]; role_id: string }[]) =>
	fetchResource(`user/group/${uuid}/invite/user`, z.object({}), "POST", invites, {
		emptyResponse: true,
		apiVersion: "v2",
	});

/**
 * Join public group
 */
export const postJoinGroup = (uuid: Group["uuid"]) =>
	fetchResource(`group/${uuid}/join`, z.object({}), "POST", undefined, { emptyResponse: true });

/**
 * Fetch group members (with top members, filter them out if you want to show them separately)
 * @param role either an array or a string of roles to filter users by
 */
export const getGroupMembers = cache(
	(uuid: Group["uuid"], page: number, q?: string, role?: string | string[]) =>
		fetchResource(
			`group/${uuid}/members?page=${page}${role ? `&roles=${Array.isArray(role) ? role.join(",") : role}` : ""}${
				q ? `&q=${q}` : ""
			}`,
			z.array(groupUser),
			"GET",
			undefined,
			{ apiVersion: "v2" },
		),
	"members",
);

/**
 * Remove group members, only group admin can perform this action
 * @param userUIDs an array of IDs of users to remove
 */
export const deleteGroupMember = (uuid: Group["uuid"], userUIDs: User["uid"][]) =>
	fetchResource(`group/${uuid}/members`, z.object({}), "DELETE", userUIDs, { emptyResponse: true });

/**
 * Fetch user's joined groups
 */
export const getJoinedGroups = (page: number) => fetchResource(`user/group?page=${page}`, z.array(groupSchema));

/**
 * Delete group, only group admin can perform this action
 */
export const deleteGroup = (uuid: Group["uuid"]) =>
	fetchResource(`group/${uuid}`, z.object({}), "DELETE", undefined, { emptyResponse: true });

/**
 * Fetch posts belonging to a group
 */
export const getGroupFeed = async (uuid: Group["uuid"], page: number) => {
	const res = await fetchResource(`group/${uuid}/feed?page=${page}`, z.array(tracklistSchema));
	return res.map((tr) => ({ ...tr, tracks: filterTracks(tr.tracks) }));
};

/**
 * Leave specified group
 */
export const postLeaveGroup = (uuid: Group["uuid"]) =>
	fetchResource(`group/${uuid}/leave`, z.object({}), "POST", undefined, { emptyResponse: true });

/**
 * Search users which can be invited to a group
 */
export const getGroupInviteMembersSearch = (term: string, uuid: Group["uuid"], page: number) =>
	fetchResource(`group/${uuid}/users/search?term=${term}&page=${page}`, z.array(oldUserSchema));

/**
 * Get all available roles user can have
 */
export const getGroupRoles = () => fetchResource("group/roles", groupRoleSchema.array());

/**
 * Update or set group's photo
 */
export const patchGroupPhoto = (uuid: Group["uuid"], data: FormData) =>
	fetchResource(`group/${uuid}/photo`, z.object({}), "PATCH", data, {
		formData: true,
		emptyResponse: true,
	});

/**
 * Edit group
 */
export const patchGroup = (uuid: Group["uuid"], data: z.infer<typeof addGroupSchema>) => {
	const links = fixGroupLinks(data.links);

	return fetchResource(`group/${uuid}`, groupSchema.pick({ uuid: true, name: true }), "PATCH", { ...data, links });
};

/**
 * Get group member and their role
 */
export const getGroupMember = async (
	uuid: Group["uuid"],
	uid: User["uid"],
	fallBackUser: GroupUser,
): Promise<GroupUser> => {
	try {
		return await fetchResource(`group/${uuid}/member/${uid}`, groupUser);
	} catch {
		return fallBackUser;
	}
};

/**
 * Change the role of a user
 */
export const patchGroupUserRole = (uuid: Group["uuid"], uid: User["uid"], role: string) =>
	fetchResource(`group/${uuid}/member/${uid}`, z.object({}), "PATCH", { role }, { emptyResponse: true });
