import Cookies from "@components/Cookies";
import DownloadApp from "@components/DownloadApp";
import Toaster from "@components/Toaster";
import { PreferencesProvider } from "@contexts/Preferences";
import useAuth from "@hooks/useAuth";
import NavigateToTracklist from "@layouts/App/components/NavigateToTracklist";
import { Navigate, Route, Router } from "@solidjs/router";
import { loadGroup } from "@views/App/Group/data";
import { type ParentComponent, Suspense, type VoidComponent, lazy } from "solid-js";
import { AppWrapper } from "./styled";

const Root: ParentComponent = (props) => {
	return (
		<PreferencesProvider>
			<AppWrapper>{props.children}</AppWrapper>
			<DownloadApp />
			<Cookies />
			<Toaster />
		</PreferencesProvider>
	);
};

const App: VoidComponent = () => {
	const { currentUser } = useAuth();

	return (
		<Suspense>
			<Router base="/" root={Root}>
				<Route path="getapp" component={lazy(() => import("@layouts/GetApp"))} />
				<Route path="embed/tracklist/:uuid/*index" component={lazy(() => import("@layouts/Embed"))} />
				<Route path="auth" component={lazy(() => import("@layouts/Auth"))}>
					<Route path="register/1" component={lazy(() => import("@views/Auth/Register/views/NewAccount"))} />
					<Route path="register/2" component={lazy(() => import("@views/Auth/Register/views/Signature"))} />
					<Route path="register/3" component={lazy(() => import("@views/Auth/Register/views/Preferences"))} />
					<Route path="register/*" component={lazy(() => import("@views/Auth/Register/views/Main"))} />
					<Route path="*" component={lazy(() => import("@views/Auth/Login"))} />
				</Route>

				<Route path="public" component={lazy(() => import("@layouts/Public"))}>
					<Route path="tracklist/:uuid/*index" component={lazy(() => import("@views/App/TracklistDetail"))} />
					<Route path="t/:uuid/*index" component={() => <NavigateToTracklist />} />
					<Route path="/*" component={() => <Navigate href="/auth/register" />} />
				</Route>
				<Route path="docs" component={lazy(() => import("@layouts/Docs"))}>
					<Route path="qr/:uuid" component={lazy(() => import("@views/Docs/QR"))} />
					<Route path=":file" component={lazy(() => import("@views/Docs/File"))} />
				</Route>
				<Route path="landing" component={lazy(() => import("@layouts/Landing"))}>
					<Route path="communities" component={lazy(() => import("@views/Landing/Community/"))}>
						<Route path="build" component={lazy(() => import("@views/Landing/Community/subviews/Build"))} />
						<Route path="schools" component={lazy(() => import("@views/Landing/Community/subviews/Schools"))} />
						<Route path="work" component={lazy(() => import("@views/Landing/Community/subviews/Work"))} />
						<Route path="friends" component={lazy(() => import("@views/Landing/Community/subviews/Friends"))} />
					</Route>
					<Route path="listener" component={lazy(() => import("@views/Landing/Listener"))}>
						<Route path="music" component={lazy(() => import("@views/Landing/Listener/subviews/Music"))} />
						<Route path="podcasts" component={lazy(() => import("@views/Landing/Listener/subviews/Podcasts"))} />
						<Route path="voxtalks" component={lazy(() => import("@views/Landing/Listener/subviews/Voxtalks"))} />
						<Route path="news" component={lazy(() => import("@views/Landing/Listener/subviews/News"))} />
						<Route path="communities" component={lazy(() => import("@views/Landing/Listener/subviews/Communities"))} />
						<Route path="radio" component={lazy(() => import("@views/Landing/Listener/subviews/Radio"))} />
					</Route>
					<Route path="creator" component={lazy(() => import("@views/Landing/Creator"))}>
						<Route path="music" component={lazy(() => import("@views/Landing/Creator/subviews/Music"))} />
						<Route path="voxtalks" component={lazy(() => import("@views/Landing/Creator/subviews/Voxtalks"))} />
						<Route path="voice" component={lazy(() => import("@views/Landing/Creator/subviews/Voice"))} />
						<Route path="rss" component={lazy(() => import("@views/Landing/Creator/subviews/RSS"))} />
					</Route>
					<Route path="guide" component={lazy(() => import("@views/Landing/Guide"))}>
						<Route path="tourism" component={lazy(() => import("@views/Landing/Guide/subviews/Tourism"))} />
						<Route path="voice" component={lazy(() => import("@views/Landing/Guide/subviews/Voice"))} />
						<Route path="camera" component={lazy(() => import("@views/Landing/Guide/subviews/Camera"))} />
						<Route path="geo" component={lazy(() => import("@views/Landing/Guide/subviews/Geo"))} />
					</Route>

					<Route path="*" component={lazy(() => import("@views/Landing/Main"))} />
				</Route>
				<Route path="" component={() => <Navigate href="/landing/*" />} />
				<Route component={lazy(() => import("@layouts/App"))}>
					<Route path="home" component={lazy(() => import("@views/App/Home"))} />
					<Route path="/discover" component={lazy(() => import("@views/App/Discover"))}>
						<Route path="*" component={lazy(() => import("@views/App/Discover/subviews/TrackLists"))} />
						<Route path="voxers" component={lazy(() => import("@views/App/Discover/subviews/Voxers"))} />
						<Route path="podcasts" component={lazy(() => import("@views/App/Discover/subviews/Podcasts"))} />
						<Route path="radios" component={lazy(() => import("@views/App/Discover/subviews/Radios"))} />
					</Route>
					<Route path="/notifications" component={lazy(() => import("@views/App/Notifications"))} />
					<Route path="/groups" component={lazy(() => import("@views/App/Groups"))} />
					<Route path="/discover/*" component={lazy(() => import("@views/App/Discover"))} />
					<Route path="/history" component={lazy(() => import("@views/App/History"))} />
					<Route path="/home" component={lazy(() => import("@views/App/Home"))} />
					<Route path="/queue" component={lazy(() => import("@views/App/Queue"))} />
					<Route path="/tracklist/:uuid/*index" component={lazy(() => import("@views/App/TracklistDetail"))} />
					<Route path="/t/:uuid/*index" component={() => <NavigateToTracklist />} />
					<Route path="/settings" component={lazy(() => import("@views/App/Settings"))}>
						<Route path="/preferences" component={lazy(() => import("@views/App/Settings/subviews/Preferences"))} />
						<Route path="/signature" component={lazy(() => import("@views/App/Settings/subviews/Signature"))} />
						<Route path="/password" component={lazy(() => import("@views/App/Settings/subviews/Password"))} />
						<Route path="/notifications" component={lazy(() => import("@views/App/Settings/subviews/Notifications"))} />
						<Route path="/privacy" component={lazy(() => import("@views/App/Settings/subviews/Privacy"))} />
						<Route path="/language" component={lazy(() => import("@views/App/Settings/subviews/Language"))} />
						<Route path="/theme" component={lazy(() => import("@views/App/Settings/subviews/Theme"))} />
						<Route path="/rss" component={lazy(() => import("@views/App/Settings/subviews/Rss"))} />
						<Route path="*" />
					</Route>
					<Route path="/search/:searchTerm" component={lazy(() => import("@views/App/Search"))}>
						<Route path="/tracklists" component={lazy(() => import("@views/App/Search/subviews/Tracklists"))} />
						<Route path="/users" component={lazy(() => import("@views/App/Search/subviews/Users"))} />
						<Route path="/groups" component={lazy(() => import("@views/App/Search/subviews/Groups"))} />
					</Route>
					<Route path="create" component={lazy(() => import("@views/App/Create"))} />
					<Route path="profile/:uuid" component={lazy(() => import("@views/App/Profile"))} />
					<Route path="myvoxers" component={lazy(() => import("@views/App/MyVoxers"))} />
					<Route
						path="group/:uuid"
						component={lazy(() => import("@views/App/Group"))}
						load={currentUser() ? loadGroup : undefined}
					/>
					<Route path="bookmarks/*" component={lazy(() => import("@views/App/Bookmarks"))} />
					<Route path="bio/:uuid" component={lazy(() => import("@views/App/Bio"))} />
					<Route path="editProfile" component={lazy(() => import("@views/App/EditProfile"))} />
					<Route path="chat/:uuid?" component={lazy(() => import("@views/App/Chat"))} />
					<Route path="/*" component={() => <Navigate href="/home" />} />
				</Route>
			</Router>
		</Suspense>
	);
};
export default App;
