import { AltText } from "@components/Layout";

const auth = {
	card: {
		header: "A world of listening in one app",
		content:
			"With voxtox® you will only listen to what you are interested in. Download the world's first streaming audio platform that lets you create, moderate, comment, manage and share any audio program, including music, in one place. With voxtox, anyone can easily create a professional listening flow.",
	},
	rights: () => `Copyright © THE INVITER TRANSACTIONS LTD., ${new Date().getFullYear()}. All right reserved.`,
	password: "password",
	agreeWith: "by continuing you agree with",
	login: {
		header: "log in",
		loginWith: "login with",
		register: () => (
			<>
				Do not have an account? <AltText>Register</AltText>
			</>
		),
		forgotPassword: () => (
			<>
				Forgot your <AltText>password</AltText>?
			</>
		),
		logging: {
			loading: "logging in",
			success: "logged in",
		},
		resetPassword: {
			reset: "reset",
			loading: "sending email",
			success: "email sent",
		},
		noAcc: () => (
			<>
				Do not have an account? <AltText>Register</AltText>
			</>
		),
	},
	errors: (type: string) =>
		({
			"auth/weak-password": "Your password is not strong enough",
			"auth/email-already-in-use": "Your e-mail is already used by another account",
			"auth/invalid-email": "Your e-mail is not valid",
			"auth/user-not-found": "This account does not exist",
			"auth/wrong-password": "Wrong password or account is not verified",
			"auth/too-many-requests": "Your account is temporally locked due to many failed log in attempts",
		})[type] ?? "",
	register: {
		title: "register with",
		nextStep: "next step",
		alreadyHaveAcc: "Already have an account? Log in.",
		agreeWith: "I agree with",
		termsReg: "terms of service",
		privacy: "privacy and security",
		step1: {
			title: "new account",
			subTitle: "select your avatar and nickname",
			nickname: "nickname",
		},
		step2: {
			title: "signature",
			subTitle: "record your audio sign",
			recordLater: "record later",
			uploadFromDevice: "upload from your device",
		},
		step3: {
			selectLater: "select later",
		},
		header: "register",
	},
	mustLogin: "you must be logged in to delete your account",
};

export default auth;
