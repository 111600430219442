import { pt } from "@trans/utils";
import type appEn from "../en/app";
import createHelp from "./app/createHelp";
import createSoundEffects from "./app/createSoundEffects";
import firebaseContent from "./app/firebaseNotificationsContent";
import content from "./app/notificationsContent";

const app: typeof appEn = {
	nav: {
		home: "domů",
		myProgrammes: "moje programy",
		bookmarks: "záložky",
		history: "moje historie",
		settings: "nastavení",
		logout: "odhlásit se",
		back: "zpět",
	},
	discover: {
		header: "objevit",
		navbar: {
			tracklist: "programy",
			voxers: "voxeři",
			podcasts: "podcasty",
			radio: "radio",
		},
		voxers: {
			unfollow: "přestat sledovat",
			follow: "sledovat",
		},
	},
	myvoxers: {
		header: "moji voxeři",
	},
	notifications: {
		content,
		leaveGroup: "Opustit skupinu",
		joinGroup: "Připojit se",
		show: "Zobrazit",
		readAll: "Přečíst vše",
		header: "notifikace",
		firebaseContent,
		allow: "Povolit notifikace?",
		disable: "Zakázat notifikace",
	},
	groups: {
		header: "skupiny",
		createdBy: "vytvořil/a",
		top: "top voxeři",
		noTop: "Tato skupina nemá žádné programy pro zobrazení top voxerů",
		add: "vytvořit skupinu",
		addHeader: "vytvořte novou skupinu",
		addDescription: "vytvořte skupinu a pozvěte nové členy",
		name: "název skupiny",
		visibility: {
			header: "viditelnost",
			public: "veřejná",
			private: "soukromá",
		},
		addMembers: "přidat členy",
		toBeInvited: (count: number) =>
			pt(
				{
					zero: "žádný voxer nebude pozván",
					one: "1 voxer bude pozván",
					few: `${count} voxeři budou pozváni`,
					other: `${count} voxerů bude pozváno`,
				},
				count,
			),
		search: "hledat ve skupině",
		memberName: "jméno voxera",
		memberList: "seznam členů",
		removeMember: "odebrat člena ze skupiny?",
		removing: {
			loading: "odstraňování",
			success: (user: string) => `uživatel ${user} byl odstraněn`,
			error: (user: string) => `uživatel ${user} nemohl být odstraněn`,
		},
		invite: "pozvat",
		invited: "pozvaný",
		inviting: {
			loading: "probíhá odesílání pozvánek",
			success: "pozvánky odeslány",
			error: "odeslání se nezdařilo",
		},
		joining: {
			loading: "probíhá připojování",
			success: "připojen",
			error: "připojení se nezdařilo",
		},
		leaving: {
			loading: "probíhá opouštění",
			success: "skupina opuštěna",
			error: "nezdařilo se opustit skupinu",
		},
		creating: {
			loading: "probíhá vytváření skupiny",
			success: (name: string) => `skupina ${name} byla vytvořena`,
			error: "nezdařilo se vytvořit skupinu",
		},
		delete: "smazat skupinu?",
		addProgramme: "vytvořit program",
		members: (count) => `${count} člen${pt({ few: "i", other: "ů" }, count, "cs")}`,
		addAdmins: "přidat administrátory",
		addMods: "přidat moderátory",
		photo: "přidejte fotku skupiny",
		description: "popis skupiny",
		website: "webový odkaz",
		tags: {
			select: "vyberte až 5 tagů",
			music: "hudební tagy",
			search: "vyhledejte tagy",
		},
		edit: "upravit skupinu",
		noDescription: "skupina nemá popis",
		privacy: "viditelnost skupiny",
		admins: "administrátoři",
		moderators: "moderátoři",
		about: "o skupině",
		manageMembers: "správa členů",
		manageMember: "správa člena",
		manage: "správa",
		makeAdmin: "nastavit roli administrátor",
		makeModerator: "nastavit roli moderátor",
		makeUser: "nastavit roli běžný uživatel",
		deleteMember: "odebrat člena ze skupiny",
		searchUsers: "najít člena",
		noPostPerm: "nemáte dostatečná oprávnění na tvorbu programů v této skupině",
	},
	history: {
		header: "historie",
	},
	programme: {
		report: "nahlásit",
		share: "sdílet",
		unshare: "přestat sdílet",
		tracks: (count: number) => `${count} ${pt({ one: "nahrávka", few: "nahrávky", other: "nahrávek" }, count)}`,
		sharing: {
			loading: "ukládání",
			success: "sdíleno",
			error: "akce se nezdařila",
		},
		reporting: {
			success: "nahlášeno",
		},
		comments: {
			header: "komentáře",
			add: "přidat komentář",
		},
		programmes: "programy",
		delete: "smazat program?",
		notFound: "program neexistuje",
		noProgrammes: "žádné programy",
		addNew: "přidejte svůj program",
		play: "přehrát program",
		pause: "pozastavit program",
		signIn: "přihlaste se do voxtox",
		list: "program seznam",
	},
	settings: {
		header: "nastavení",
		save: "uložit",
		menu: {
			preferences: "preference",
			signature: "podpis",
			password: "heslo",
			notifications: "notifikace",
			privacy: "soukromí a bezpečnost",
			language: "jazyk",
			rss: "importovat feed",
			theme: "motiv",
		},
		password: {
			header: "heslo",
			currentPassword: "stávající heslo",
			newPassword: "nové heslo",
			confirmPassword: "znovu zadejte heslo",
			success: "heslo úspěšně změněno",
			loading: "měnění hesla",
		},
		theme: {
			lightTheme: "světlý motiv",
			darkTheme: "tmavý motiv",
		},
		notifications: {
			loading: "aktualizujeme nastavení notifikací",
			success: "aktualizováno",
		},
		privacy: {
			header: "kdo může vidět můj obsah?",
			loading: "aktualizace",
			success: "úspěšně aktualizováno",
			everybody: "všichni",
			onlyMe: "pouze já",
			open: "otevřít",
			dateOfRegister: "datum registrace",
			privacyDoc: "osobní data a soukromí",
			terms: "podmínky služby voxtox",
			deleteProf: {
				title: "smazat účet",
				loading: "mazání účtu",
				success: "na email vám byl odeslán potvrzující odkaz pro smazání účtu",
			},
			delConfirm: {
				error: "mazání účtu se nezdařilo",
				loading: "mazání účtu",
				success: "účet byl smazán",
			},
		},
		rss: {
			import: (date: string) => `naposledy importováno: ${date}`,
			never: "nikdy",
			timing: "import probíhá jednou denně",
			loading: "nahrávání",
			success: "nahráno",
			error: "neplatná hodnota",
		},
		signature: {
			uploadFromDevice: "nahrát ze zařízení",
			click: "klikněte pro nahrání podpisu",
			del: "smazat podpis",
		},
		preferences: {
			loading: "ukládání preferencí",
			success: "preference uloženy",
		},
	},
	search: {
		voxtox: "hledat na voxtox",
		noTracks: "žádné skladby",
		noGroups: "žádné skupiny",
	},
	users: {
		header: "uživatelé",
	},
	create: {
		header: "vytvořit",
		help: createHelp,
		storage: {
			header: "skladiště",
			empty: "složka je prázdná",
		},
		drafts: {
			header: "koncepty",
		},
		track: {
			music: "hudba",
			search: "hledat",
			upload: "vložit",
			record: "nahrát",
			edit: {
				name: "název nahrávky",
				image: "obrázek nahrávky",
				private: "zamknout sdílení",
			},
			clickToRecord: "klikněte pro nahrání nové nahrávky",
			uploading: "načítání",
			create: {
				converting: "vaše nahrávka se zpracovává",
				noCut: "nestříhat",
			},
			added: (name: string) => (
				<>
					<strong>nahrávka {name} byla přidána</strong>
				</>
			),
		},
		publish: {
			header: "uložte program",
			selectImage: "vyberte obrázek programu",
			categories: "kategorie",
			location: "umístění",
			publishAt: "plánovač",
			total: "celkem",
			noGroup: "bez skupiny",
			publish: "zveřejnit",
			done: "hotovo",
		},
		editor: {
			play: "přehrát",
			pause: "pozastavit",
			stop: "stop",
			copy: "kopírovat",
			cut: "střih",
			record: "nahrajte svůj hlas",
			soundEffects: "zvukové efekty",
			upload: "vložit a nahrát",
			voiceEffects: "hlasové efekty",
		},
		soundEffects: createSoundEffects,
		cutTrack: "rozdělte soubor na části",
	},
	profile: {
		header: "profil",
		gonnaBePub: "bude publikováno:",
		pubNow: "zveřejnit teď",
		userNotFound: "Tento profil je buď soukromý, nebo neexistuje",
		following: "sledujete",
		followers: "sledujících",
		map: "mapa",
		generateQR: "moje QR",
		live: "živě",
		show: "zobrazit profil",
		findMore: "najděte více voxerů",
		editProfile: "upravit profil",
		search: "hledat v obsahu voxera",
		block: "blokovat",
		unblock: "odblokovat",
		followingOther: "sleduje",
	},
	editProfile: {
		categories: "kategorie",
		gender: "pohlaví",
		workingAs: "zaměstnání",
		city: "město",
		interestedIn: "zájmy",
		inspiredBy: "inspirace",
		tts: "převod textu na mluvené slovo",
		chooseVoice: "vyberte hlas",
		birthday: "datum narození",
		editLanguages: "upravit jazyky",
		selectCategory: "vybrat kategorie",
		selectGender: "vybrat pohlaví",
		gallery: "galerie",
	},
	tts: {
		advanced: "pokročilý mód",
		limit: (used: number, max: number) => `využito ${used} z ${max} znaků`,
		preview: "přehrajte si váš text",
		error: "přehrání ukázky se nezdařilo",
		simple: "jednoduchý mód",
	},
	error: {
		header: "Něco se pokazilo a už na tom pracujeme!",
		youCan: "Můžete vyzkoušet obnovení. Pokud se chyba objeví znovu, můžete jít zpět na domovskou stránku",
		recover: "vyzkoušet obnovení",
		home: "zpět na domovskou stránku",
	},
	comment: {
		voice: "nahrát můj hlas",
		tts: "převod textu na řeč",
		click: "klikněte pro nahrání vašeho komentáře",
		delete: "smazat komentář?",
		comment: "komentář",
	},
	location: {
		you: "vaše pozice",
		back: "zpět na moji pozici",
		address: "napište adresu",
	},
	chat: {
		header: "chat",
		newConversation: "nová konverzace",
		voiceMessage: "hlasová zpráva",
		emptySearchUsers: "nenalezeni žádní voxeři",
		viewed: "zobrazeno",
		uploadVoiceMessage: "nahrajte audio zprávu",
	},
};
export default app;
