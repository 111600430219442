import { AltText } from "@components/Layout";
import type authEn from "../en/auth";

const auth: typeof authEn = {
	card: {
		header: "Svět poslechu v jedné aplikaci",
		content:
			"S voxtox® budete poslouchat už jen to, co vás zajímá. Stáhněte si první streamovací audio platformu na světě, která umožňuje vytvářet, moderovat, komentovat, spravovat a sdílet jakýkoli zvukový program včetně hudby na jednom místě. S voxtox může každý lehce vytvořit profesionální poslechové flow.",
	},
	rights: () => `Copyright © THE INVITER TRANSACTIONS LTD., ${new Date().getFullYear()}. Všechna práva vyhrazena.`,
	password: "heslo",
	agreeWith: "používáním aplikace souhlasíte s ",
	login: {
		header: "přihlásit se",
		loginWith: "přihlásit se pomocí",
		register: () => (
			<>
				Ještě nemáte účet? <AltText>Zaregistrujte se.</AltText>
			</>
		),
		forgotPassword: () => (
			<>
				Zapomněli jste <AltText>heslo</AltText>?
			</>
		),
		logging: {
			loading: "Probíhá přihlašování",
			success: "Přihlášeno",
		},
		resetPassword: {
			reset: "resetovat",
			loading: "posílání emailu",
			success: "email odeslán",
		},
		noAcc: () => (
			<>
				Ještě nemáte účet? <AltText>Zaregistrujte se.</AltText>
			</>
		),
	},
	errors: (type: string) =>
		({
			"auth/weak-password": "Vaše heslo není dostatečně bezpečné",
			"auth/email-already-in-use": "Tento e-mail je už svázán s jiným účtem",
			"auth/invalid-email": "Váš e-mail není validní",
			"auth/user-not-found": "Tento účet neexistuje",
			"auth/wrong-password": "Špatné heslo, nebo účet není ověřený",
			"auth/too-many-requests": "Váš účet je dočasně zablokován kvůli mnoha neúspěšným pokusům o přihlášení.",
		})[type] ?? "",
	register: {
		title: "zaregistrujte se pomocí",
		nextStep: "další krok",
		alreadyHaveAcc: "Už máte účet? Přihlaste se.",
		agreeWith: "Souhlasím s",
		termsReg: "podmínkami služby voxtox",
		privacy: "soukromí a bezpečnosti",
		step1: {
			title: "nový účet",
			subTitle: "nastavte si svého avatara a svoji přezdívku",
			nickname: "přezdívka",
		},
		step2: {
			title: "podpis",
			subTitle: "nahrajte svůj zvukový podpis",
			recordLater: "nahrát později",
			uploadFromDevice: "vložit ze zařízení",
		},
		step3: {
			selectLater: "vybrat později",
		},
		header: "registrovat",
	},
	mustLogin: "musíte se přihlásit pro smazání svého účtu",
};

export default auth;
