import type { ApiError } from "@src/app/types/api";
import { formatDate } from "@src/app/utils";
import { pt } from "@trans/utils";
import type { StringValidation, ZodParsedType, ZodTooBigIssue, ZodTooSmallIssue } from "zod";

const types: Record<keyof typeof ZodParsedType, string> = {
	number: "number",
	string: "string",
	integer: "integer number",
	float: "float number",
	boolean: "boolean",
	date: "date",
	array: "pole",
	bigint: "číslo",
	function: "funkce",
	map: "map",
	never: "nikdy",
	null: "null",
	object: "objekt",
	promise: "promise",
	set: "set",
	symbol: "symbol",
	undefined: "nedefinovaná",
	unknown: "neznámá",
	void: "void",
	nan: "not a number",
} as const;

const tooSmall: Record<ZodTooSmallIssue["type"], (min: number) => string> = {
	array: (min: number) => `Array has to have at least ${min} item${pt({ other: "s" }, min)}`,
	bigint: (min: number) => `Value must have be greater or equal to ${min}`,
	date: (min: number) => `Date must be greater or equal to ${formatDate(new Date(min))}`,
	number: (min: number) => `Value must have be greater or equal to ${min}`,
	set: (min: number) => `Array has to have at least ${min} item${pt({ other: "s" }, min)}`,
	string: (min: number) => `Value must have at least ${min} character${pt({ other: "s" }, min)}`,
};
const tooBig: Record<ZodTooBigIssue["type"], (max: number) => string> = {
	array: (max: number) => `Array has to have maximum of ${max} item${pt({ other: "s" }, max)}`,
	bigint: (max: number) => `Value must have be lower or equal to ${max}`,
	date: (max: number) => `Date must be lower or equal to ${formatDate(new Date(max))}`,
	number: (max: number) => `Value must have be lower or equal to ${max}`,
	set: (max: number) => `Array has to have maximum of ${max} item${pt({ other: "s" }, max)}`,
	string: (min: number) => `Value must have maximum of ${min} character${pt({ other: "s" }, min)}`,
};

export const apiErrors: Record<ApiError, string> = {
	"User is blocked": "you are blocked",
	GENERIC: "something is wrong",
	INVALID_AUDIO_DATA: "invalid audio data",
};

const errors = {
	email: "Value must be an e-mail",
	fileType: (type: string) => `File must not be ${type}`,
	fileSize: (size: number) => `File must be at least ${size}x${size}px`,
	min: tooSmall,
	max: tooBig,
	phone: "Invalid phone number",
	int: "Value must be an integer",
	nonnegative: "Value must be greater or equal to zero",
	type: (type: keyof typeof types) => `Value must be ${types[type]}`,
	string: (type: StringValidation) =>
		typeof type === "string" ? `Value is not valid ${type}` : "Value has unknown value",
	unsupportedAudio:
		"Your browser does not support decoding audio files of these types, try another file or another browser",
	api: apiErrors,
};

export default errors;
