import { AltText } from "@components/Layout";

const landing = {
	main: {
		header: () => (
			<span style={{ "font-weight": 400 }}>
				<b>it's more</b> than your audio app
			</span>
		),
		playMe: "Play me",
		text: "voxtox is your first social audio platform. Be yourself, express yourself. Be a creator. Be a listener. Inspire and be inspired. The world around you in your ears",
		start: "Start using for free",
		continue: (name: string) => `Continue with ${name}`,
		email: "Register with email",
		getApp: "GET THE APP",
		try: "Try your personal voxtox right now!",
		open: "Open voxtox in browser",
		free: "For Free!",
		getVoxApp: "Get the voxtox.fm app",
		scanQr: "Scan this QR code to download the app now",
		orAppStores: "Or check it out in the app stores",
	},
	listener: {
		header: "Be a listener",
		text: () => (
			<>
				Stop scrolling. Just listen to. <AltText>Everything YOU NEED</AltText> in one app. Made tailored for you.
				Authentic.voxtox
			</>
		),
		subviews: {
			music: {
				header: "Music",
				text: "Your Playlist Paradise: Millions of Beloved Tunes, Effortless and Cost-Free! Listen across your devices.",
			},
			podcasts: {
				header: "Podcasts",
				text: "Blend Thousands of Favorites and Exclusive Episodes with Your Music - Welcome to Your Personal Station!",
			},
			voxtalks: {
				header: "Voxtalks",
				text: "Your Personal Audio Gallery: Voxtalks Presents Fascinating Stories of People, Family, Friends, Celebrities, and More - Tune In and Be Moved!",
			},
			news: {
				header: "News",
				text: "Explore Curated or Self-Selected Stories - Stay Informed When It Suits You!",
			},
			communities: {
				header: "Communities",
				text: "Stay informed about everything you love! All your communities at one place: friends and family, hobbies, work, your place and much more",
			},
			radio: {
				header: "Radio",
				text: "Stay informed about everything you love! All your communities at one place: friends and family, hobbies, work, your place and much more",
			},
		},
	},
	creator: {
		header: "Be a creator",
		text: "Create, share, inspire! Be the architect of the audio world. Share your playlists, programs, podcasts, interviews and much more. With a few clicks.",
		subviews: {
			music: {
				header: "Music",
				text: "Your Personal Audio Studio: Shape Your Own Programs and Playlists - Share with the World or Connect in Groups!",
			},
			voxtalks: {
				header: "Voxtalks",
				text: "Share Your Thoughts, Ideas, and Opinions - Publicly, Privately, in Groups, or Your Own Shows! Join Live Discussions with your voice!",
			},
			voice: {
				header: "Text voice bot",
				text: "Voice Your Text: Embrace the Power of Text-to-Speech - Give Voice to Your Ideas and Share! Speak, Comment and so much more!",
			},
			rss: {
				header: "RSS magic",
				text: "Share Your Audio Creations with the World - Easily Add Your Channel to Voxtox!",
			},
		},
	},
	guide: {
		header: "Audio guides",
		create: "create guide",
		subviews: {
			main: {
				header: "Let your ears guide you",
				text: "Exploring made easy with audio. Promote your destination worldwide with audio guide for everyone. For free.",
			},
			tourism: {
				header: "Elevate tourism",
				text: "Your city, all languages: voxtox's user friendly app lets you create multi-languages audio tours hassle free!",
			},
			scan: {
				header: "Scan, listen, discover",
				text: "One QR code - 30 languages! No app needed, just phone's camera!",
			},
			voice: {
				header: "QR codes speaks all tongues",
				text: "Record your voice, upload your audio or create your audio guide with AI voice bot!",
			},
			geo: {
				header: "Geo-Push Magic",
				text: "Discover Attractions with Push Notifications and Audio Guides on the Map! Lead You to Personalized Audio Tours!",
			},
			camera: {
				header: "Your Camera, Your Guide",
				text: "Seamless Audio Tours: Voxtox Makes It Easy - Tourists Enjoy in Their Language with Just Their Phone's Camera!",
			},
		},
	},
	communities: {
		header: "Communities",
		text: "Exploring made easy with audio. Promote your destination worldwide with audio guide for everyone. For free.",
		subviews: {
			build: {
				header: "Build your community",
				text: "It's the place where you can create your own groups, connect with like-minded individuals, and together, thrive in a supportive and engaging environment.",
			},
			schools: {
				header: "Schools and education",
				text: "We empower schools to create radio platforms for students to communicate and receive essential info in an engaging format. Students learn through entertaining content, staying connected with their school community, much like their parents",
			},
			work: {
				header: "Work and colleges ",
				text: "Empower your teams with an efficient tool for communication, information dissemination, internal podcasts, employee training, and more. Enhance workplace collaboration and engagement with our platform",
			},
			friends: {
				header: "Friends and family",
				text: "Create Your Personal Groups to Connect with Loved Ones, Share Life's Moments, and Stay Close. Likewise, Connect with Friends, Share Adventures, and Cherish Memories Together",
			},
		},
	},
	advertising: {
		header: "Advertising",
		text: "Experience efficient and data-driven advertising on our platform. Target your audience precisely with geolocation targeting, optimize campaigns in real-time, and enjoy cost-effective advertising with transparent reporting and reduced ad fraud risk.",
	},
	footer: {
		header: "Contact us if you have any questions",
		home: "home",
	},
};
export default landing;
