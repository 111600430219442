import Button from "@components/Button";
import { Card, Row } from "@components/Layout";
import useI18n from "@contexts/I18nProvider/hooks/useI18n";
import type { VoidComponent } from "solid-js";
import { CodeBlock } from "./styled";

const ErrorOverlay: VoidComponent<{ error: string }> = (props) => {
	const { t } = useI18n();
	return (
		<Card
			w="100dvw"
			h="100dvh"
			style={{ position: "fixed", "z-index": 512 }}
			alignItems="center"
			justifyContent="center"
			gap="20px"
			borderRadius="0"
		>
			<h1>{t("app.error.header")}</h1>
			<Row margin="20px 0 50px" justifyContent="center">
				<h3>{t("app.error.youCan")}</h3>
			</Row>
			<Button onClick={() => window.location.reload()}>{t("app.error.recover")}</Button>
			<Row margin="20px 0" justifyContent="center">
				<Button
					onClick={() => {
						window.location.href = "/";
					}}
				>
					{t("app.error.home")}
				</Button>
			</Row>
			<CodeBlock>{props.error}</CodeBlock>
		</Card>
	);
};
export default ErrorOverlay;
