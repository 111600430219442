import { setToStorage } from "@src/app/utils";
import type { AvailableLanguage } from "@trans/index";
import { useContext } from "solid-js";
import { i18nContext } from "../..";

const useI18n = () => {
	const { t, setLocale, locale, chained } = useContext(i18nContext);

	if (!t || !chained) throw new Error("Translator not available");

	const setLanguage = (lng: AvailableLanguage) => {
		setLocale(lng);
		setToStorage(lng, "lng", "user");
	};

	return { t, setLocale: setLanguage, locale, chained };
};

export default useI18n;
