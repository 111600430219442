import { type AvailableLanguage, availableLanguages } from "@app/translations/";
import { getFromStorage, setToStorage } from "@app/utils/";
import { flatten } from "@solid-primitives/i18n";
import { isLocale } from "@trans/utils";
import type { RawDictionary } from "./types";

export const getUserLocale = () => {
	const lang = getFromStorage("lng", "user") ?? navigator.language.substring(0, 2);
	const locale = isLocale(lang) ? lang : availableLanguages[0];
	setToStorage(locale, "lng", "user");
	return locale;
};

export const fetchDictionary = async (locale: AvailableLanguage) => {
	const dictionary: RawDictionary = (await import(`../../translations/${locale}/index.ts`)).default;

	return flatten(dictionary);
};
