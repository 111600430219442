import { getUserLocale } from "@contexts/I18nProvider/utils";
import { ENV } from "@src/env";
import { apiErrorSchema } from "@src/schemas";
import type { z } from "zod";
import { auth } from "./firebase";
import type { RequestMethod, ResourceOptions, StorageContext } from "./types";

/**
 * gets item from local storage
 * @param key from which key to get the data
 * @param context in which context the key is located
 */
export const getFromStorage = (key: string, context?: StorageContext) =>
	localStorage.getItem(context ? `${context}.${key}` : key) ?? null;
/**
 * adds items to teh local storage
 * @param value value to be stored
 * @param key in which key to store the given value
 * @param context which context to use, default is no context
 */
export const setToStorage = (value: string | null, key: string, context?: StorageContext) => {
	const composedKey = context ? `${context}.${key}` : key;
	if (value) {
		localStorage.setItem(composedKey, value);
	} else {
		localStorage.removeItem(composedKey);
	}
};

/**
 * fetch json from API
 * @param uri endpoint (no trailing slash on base uri and endpoint)
 * @param method request method
 * @param headers request headers
 * @param body request body
 */
export const fetchApiResource = async <T>(
	uri: string,
	method: RequestMethod = "GET",
	body?: unknown,
	formData?: boolean,
	customHeaders?: HeadersInit,
	apiUri?: string,
	apiVersion?: string,
) => {
	const lang = getFromStorage("lng", "user") ?? "cs";

	let headers = {
		"accept-language": lang,
	};
	if (!formData) {
		headers = { ...headers, ...{ "Content-Type": "application/json" } };
	}
	const organization = getFromStorage(import.meta.env.VITE_ORGANIZATION, "user");
	const token = await auth.currentUser?.getIdToken();
	if (organization) headers = { ...headers, ...{ "x-organization-id": organization } };
	if (token) headers = { ...headers, ...{ Authorization: `Bearer ${token}` } };
	if (customHeaders) headers = { ...headers, ...customHeaders };

	const defaultUri = getFromStorage("apiUri", "debug") ?? import.meta.env.VITE_API_URI;

	const response = await fetch(
		`${apiUri ?? import.meta.env.VITE_DEV ? defaultUri : import.meta.env.VITE_API_URI}/${
			apiVersion ?? import.meta.env.VITE_API_VERSION
		}/${uri}`,
		{
			method,
			headers,
			body: method === "GET" ? null : formData ? (body as BodyInit) : JSON.stringify(body),
		},
	);
	try {
		const res = await response.json();
		if (res.statusCode) return;
		return res as T;
	} catch {
		return;
	}
};

/**
 * Trim string based on set length and append a postfix
 * @param length total length, if the string is 20 chars long, length is 10 and  postfix is 3 chars long
 * then the resulting string is going to be  chars from the string and 3 chars from postfix resulting in total length of 10 chars
 * @param postfix default value is ...
 * @returns
 */
export const trimString = (string: string, length: number, postfix = "...") => {
	if (!string) return "";
	if (string.length <= length) return string;
	return `${string.slice(0, length - postfix.length)}${postfix}`;
};
/**
 * Generate seed values from given string
 */
const cyrb128 = (str: string) => {
	let h1 = 1779033703;
	let h2 = 3144134277;
	let h3 = 1013904242;
	let h4 = 2773480762;
	for (let i = 0, k = 0; i < str.length; i++) {
		k = str.charCodeAt(i);
		h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
		h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
		h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
		h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
	}
	h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
	h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
	h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
	h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
	return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
};
/**
 * Generate random number between 0 and 1 with the given string as a seed value
 */
export const sfc32 = (seed: string) => {
	let [a, b, c, d] = cyrb128(seed);
	a >>>= 0;
	b >>>= 0;
	c >>>= 0;
	d >>>= 0;
	let t = (a + b) | 0;
	a = b ^ (b >>> 9);
	b = (c + (c << 3)) | 0;
	c = (c << 21) | (c >>> 11);
	d = (d + 1) | 0;
	t = (t + d) | 0;
	c = (c + t) | 0;
	return (t >>> 0) / 4294967296;
};
/**
 * generates pseudo random integers in set range, bounds are inclusive
 * @returns pseudo random integer
 */
export const getRandomInt = (min: number, max: number, seed: string) => {
	const _min = Math.ceil(min);
	const _max = Math.floor(max);
	return Math.floor(sfc32(seed) * (_max - _min + 1)) + _min;
};

export const formatDate = (d: Date, short?: boolean) => {
	return d.toLocaleDateString(getUserLocale(), {
		day: "2-digit",
		month: "short",
		year: "numeric",
		...(short ? {} : { hour: "2-digit", minute: "2-digit", hour12: false }),
	});
};

export const http = async (uri: string, method: RequestMethod = "GET", body?: unknown, options?: ResourceOptions) => {
	const lang = getFromStorage("lng", "user") ?? "cs";

	let headers = {
		"accept-language": lang,
	};
	if (!options?.formData) {
		headers = { ...headers, ...{ "Content-Type": "application/json" } };
	}
	if (options?.customHeaders) headers = { ...headers, ...options.customHeaders };
	const token = await auth.currentUser?.getIdToken();
	if (token) headers = { ...headers, ...{ Authorization: `Bearer ${token}` } };

	const defaultUri =
		(ENV.VITE_DEV ? getFromStorage("apiUri", "debug") : undefined) ?? options?.apiUri ?? ENV.VITE_API_URI;
	const defaultVersion =
		(ENV.VITE_DEV ? getFromStorage("apiVersion", "debug") : undefined) ?? options?.apiVersion ?? ENV.VITE_API_VERSION;

	const response = await fetch(`${defaultUri}/${defaultVersion ? `${defaultVersion}/` : ""}${uri}`, {
		method,
		headers,
		body: method === "GET" ? null : options?.formData ? (body as BodyInit) : JSON.stringify(body),
		signal: options?.signal,
	});
	return response;
};

export const fetchResource = async <T extends z.ZodSchema>(
	uri: string,
	validator: T,
	method: RequestMethod = "GET",
	body?: unknown,
	options?: ResourceOptions,
) => {
	const response = await http(uri, method, body, options);

	const parsedJson = options?.emptyResponse ? {} : await response.json();

	if (!response.status.toString().startsWith("2")) {
		const errRes = options?.emptyResponse ? await response.json() : parsedJson;
		const parse = apiErrorSchema.safeParse(errRes);
		if (!parse.success) throw undefined;
		throw parse.data;
	}
	return validator.parse(parsedJson) as z.infer<typeof validator>;
};

export const fetchBlobResource = async (
	uri: string,
	method: RequestMethod = "GET",
	body?: unknown,
	options?: ResourceOptions,
) => {
	const blob = await (await http(uri, method, body, options)).blob();
	if (!blob) throw new Error("Invalid blob");
	return blob;
};

/**
 * Formats time into human readable format
 */
export const formatTime = (date: Date) => {
	const now = new Date();

	const difference = Math.round((now.getTime() - date.getTime()) / 1000);
	const lng = getFromStorage("lng", "user") ?? "en";

	const rtl = new Intl.RelativeTimeFormat(lng);
	if (difference < 60) return rtl.format(-difference, "seconds");
	if (difference >= 60 && difference < 3600) return rtl.format(Math.round(-difference / 60), "minutes");
	if (difference >= 3600 && difference < 86400) return rtl.format(Math.round(-difference / 60 / 60), "hours");
	if (difference >= 86400 && difference < 604800) return rtl.format(Math.round(-difference / 60 / 60 / 24), "days");
	if (difference >= 604_800 && difference < 2_419_200)
		return rtl.format(Math.round(-difference / 60 / 60 / 24 / 7), "weeks");
	return Intl.DateTimeFormat(lng, { day: "2-digit", month: "2-digit", year: "numeric" }).format(date);
};

/**
 * @param length length of a track(s)
 * @param leadingZeroFormat object describing if the number should start with 0 when it's smaller than 10
 * @returns formatted time by the given parameters
 */
export const formatPostTotalTime = (length: number) => {
	const l = length < 0 ? 0 : length;
	const hours = Math.floor(l / 3600);
	const minutes = Math.floor((l % 3600) / 60);

	const getPostfixHours = () => {
		const lng = getFromStorage("lng", "user") ?? "en";
		if (lng === "en") return "h";
		return "hod.";
	};

	return `${hours > 0 ? `${hours} ${getPostfixHours()}` : ""} ${minutes === 0 ? 1 : minutes} min.`;
};

/**
 * @param length length of a track(s)
 * @param leadingZeroFormat object describing if the number should start with 0 when it's smaller than 10
 * @returns formatted time by the given parameters
 */
export const formatPlayTime = (length: number, leadingZeroFormat: { h?: boolean; m?: boolean; s?: boolean }) => {
	const l = length < 0 ? 0 : length;
	const hours = Math.floor(l / 3600);
	const minutes = Math.floor((l % 3600) / 60);
	const seconds = Math.floor((l % 3600) % 60);
	const formatNumber = (number: number, leadingZero?: boolean) =>
		number < 10 ? `${leadingZero ? "0" : ""}${number}` : number;
	return `${hours !== 0 ? `${formatNumber(hours, leadingZeroFormat.h)}:` : ""}${formatNumber(
		minutes,
		leadingZeroFormat.m,
	)}:${formatNumber(seconds, leadingZeroFormat.s)}`;
};

/**
 * Shuffle array items
 * @see https://stackoverflow.com/a/12646864
 */
export const shuffleArray = <T>(array: T[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

export const formatMessageTime = (d: Date) => {
	const now = new Date();
	const lng = getFromStorage("lng", "user") ?? "en";
	const old = Math.round((now.getTime() - d.getTime()) / 1000) > 86400;

	return Intl.DateTimeFormat(lng, {
		...(old ? { day: "2-digit", month: "2-digit", year: "numeric" } : {}),
		hour: "2-digit",
		minute: "2-digit",
	}).format(d);
};

/**
 * Removes diacritics and turn text into lowercase
 */
export const normalizeText = (text: string) => {
	return (
		text
			.toLowerCase()
			.normalize("NFD")
			// biome-ignore lint/suspicious/noMisleadingCharacterClass: regex
			.replace(/[\u0300-\u036f]/g, "")
	);
};
export const capitalizeFirst = (text: string) => `${text.at(0)?.toUpperCase()}${text.substring(1)}`;
