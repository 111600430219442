import type { ApiError } from "@src/app/types/api";
import { formatDate } from "@src/app/utils";
import { pt } from "@trans/utils";
import type { StringValidation, ZodParsedType, ZodTooBigIssue, ZodTooSmallIssue } from "zod";
import type errorsEn from "../en/errors";

const types: Record<keyof typeof ZodParsedType, string> = {
	number: "číslo",
	string: "text",
	nan: "nan",
	integer: "celé číslo",
	float: "číslo s desetinnou čárkou",
	boolean: "pravda/nepravda",
	date: "datum",
	array: "pole",
	bigint: "číslo",
	function: "funkce",
	map: "map",
	never: "nikdy",
	null: "null",
	object: "objekt",
	promise: "promise",
	set: "set",
	symbol: "symbol",
	undefined: "nedefinovaná",
	unknown: "neznámá",
	void: "void",
} as const;

const tooSmall: Record<ZodTooSmallIssue["type"], (min: number) => string> = {
	array: (min: number) => `Pole musí mít alespoň ${min} položek`,
	bigint: (min: number) => `Hodnota musí být větší, nebo rovna ${min}`,
	date: (min: number) => `Datum musí být vetší než, nebo rovno ${formatDate(new Date(min))}`,
	number: (min: number) => `Hodnota musí být větší, nebo rovna ${min}`,
	set: (min: number) => `Pole musí mít alespoň ${min} položek`,
	string: (min: number) => `Hodnota musí mít aspoň ${min} znak${pt({ few: "y", other: "ů" }, min)}`,
};
const tooBig: Record<ZodTooBigIssue["type"], (max: number) => string> = {
	array: (max: number) => `Pole musí maximálně ${max} položek`,
	bigint: (max: number) => `Hodnota musí být menší, nebo rovna ${max}`,
	date: (max: number) => `Datum musí být menší než, nebo rovno ${formatDate(new Date(max))}`,
	number: (max: number) => `Hodnota musí být menší, nebo rovna ${max}`,
	set: (max: number) => `Pole musí mít maximálně ${max} položek`,
	string: (max: number) => `Hodnota musí mít maximálně ${max} znak${pt({ few: "y", other: "ů" }, max)}`,
};

export const apiErrors: Record<ApiError, string> = {
	"User is blocked": "Jste zablokováni",
	GENERIC: "Něco se pokazilo",
	INVALID_AUDIO_DATA: "Nesprávná audio data",
};

const errors: typeof errorsEn = {
	email: "Hodnota není e-mail",
	fileType: (type: string) => `Soubor nesmí být ${type}`,
	fileSize: (size: number) => `Soubor musí být minimálně ${size}x${size}px`,
	min: tooSmall,
	max: tooBig,
	phone: "Nevalidní telefonní číslo",
	int: "Hodnota musí být celé číslo",
	nonnegative: "Hodnota musí být větší, nebo rovna nule",
	type: (type: keyof typeof types) => `Hodnota musí být ${types[type]}`,
	string: (type: StringValidation) =>
		typeof type === "string" ? `Hodnota není validní ${type}` : "Hodnota má neznámou hodnotu",
	unsupportedAudio:
		"Váš prohlížeč nepodporuje dekódování souborů vámi zvoleného typu, nahrajte jiný soubor, nebo vyzkoušejte jiný prohlížeč",
	api: apiErrors,
};

export default errors;
