import { auth } from "@app/utils//firebase";
import { type User as GoogleUser, onAuthStateChanged } from "firebase/auth";
import { batch, createSignal, onMount } from "solid-js";

/**
 * Returns currently logged in firebase user
 */
const useAuth = () => {
	const [currentUser, setCurrentUser] = createSignal<GoogleUser | null>(null);
	const [loading, setLoading] = createSignal(true);

	onMount(() => {
		const listener = onAuthStateChanged(auth, (user) => {
			batch(() => {
				setCurrentUser(user);
				setLoading(false);
			});
		});
		return listener;
	});

	return { currentUser, loading };
};
export default useAuth;
