import { oldUserSchema } from "@api/user/schemas";
import { ENV } from "@src/env";
import { z } from "zod";
import { GroupPermissions } from "./types";

export const groupRoleSchema = z.object({
	id: z.string(),
	name: z.string(),
	permissions: z.preprocess((a) => {
		if (!Array.isArray(a)) return [];
		return a.filter((p) => z.nativeEnum(GroupPermissions).safeParse(p).success);
	}, z.nativeEnum(GroupPermissions).array()),
});

export const groupSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string().min(3).max(70),
	private: z.boolean(),
	author: z.lazy(() => oldUserSchema),
	created_at: z.coerce.date(),
	top_members: z.array(z.lazy(() => oldUserSchema)),
	joined: z.boolean(),
	num_members: z.number().int().nonnegative(),
	photo: z
		.string()
		.url()
		.nullable()
		.transform((v) => v ?? "/logo_no_text.png"),
	description: z.string().max(ENV.VITE_MAX_GROUP_DESCRIPTION_LENGTH),
	links: z.object({ type: z.string(), link: z.string() }).array(),
	categories: z
		.object({ id: z.number().int(), name: z.string() })
		.transform((v) => v.id)
		.array(),
	defaultRole: groupRoleSchema.pick({ id: true, name: true }),
});

export const groupUser = z.object({
	user: z.lazy(() => oldUserSchema),
	role_id: groupRoleSchema.shape.id,
});
