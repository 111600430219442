const createHelp = [
	{
		label: "add music",
		header: "Add your favourite music to the programme",
		points: [
			() => (
				<>
					Type the <strong>name of the song or artist</strong> into the search box and voxtox will search the catalogue
					for the available songs
				</>
			),
			() => (
				<>
					Simply <strong>select from these songs with the + button</strong>, you can also select several songs at once.
				</>
			),
			() => (
				<>
					<strong>Finish your selection with the SAVE button</strong> and the selected music will be added to the
					program. While the voxtox catalog doesn't contain everything, over a 4 million songs are available to you.
				</>
			),
		],
	},
	{
		label: "add from voxtox",
		header: "Search for content on voxtox",
		points: [
			() => (
				<>
					Something caught your eye at voxtox and you want it in your program? Enter the{" "}
					<strong>name of the voxer, program or specific track</strong> in the search box.
				</>
			),
			() => (
				<>
					Simply <strong>use the&nbsp;+&nbsp;button to select your track</strong> from the tracks found; you can also
					select several tracks at once.
				</>
			),
			() => (
				<>
					<strong>Finish the selection with the&nbsp;SAVE&nbsp;button</strong> and the selected tracks will be added to
					the program.{" "}
				</>
			),
		],
	},
	{
		label: "upload file",
		header: "Insert an audio file",
		points: [
			() => (
				<>
					Do you have your own recording of an interview, podcast or any other audio?
					<strong>Upload it as a file</strong> directly from your computer. We support{" "}
					<strong>MP3,&nbsp;WAV&nbsp;and more.</strong>
				</>
			),
			() => (
				<>
					Select the audio file on your device, upload it to voxtox, you can change its name, add a photo as a cover and{" "}
					<strong>use the&nbsp;SAVE&nbsp;button to upload it to the program.</strong>
				</>
			),
			() => (
				<>
					You must have <strong>resolved rights</strong> to files with third party content&nbsp;
				</>
			),
		],
	},
	{
		label: "write text",
		header: "Convert text to sound",
		points: [
			() => (
				<>
					Do you have content that is written in <strong>text and you want to convert it into an audio track</strong>?
					Choose a speaker or a spokesperson, or a dialogue if you are converting, for example, a conversation between
					two people into audio.
				</>
			),
			() => (
				<>
					Enter the text in the text box, <strong>do not leave blank paragraphs or characters</strong> in the text that
					might confuse the robot voices.
				</>
			),
			() => (
				<>
					Name the track and add a photo as a cover. <strong>Use the SAVE button to add it</strong> to the program.
				</>
			),
		],
	},
	{
		label: "record audio",
		header: "Record directly to the track",
		points: [
			() => (
				<>
					Click on the microphone&nbsp;and <strong>voxtox will start recording directly.</strong> To stop recording,
					click on the "stop" button.
				</>
			),
			() => (
				<>
					Use the play button next to the track that&nbsp;appears below the microphone to{" "}
					<strong>check your recording.</strong> Then you just give it a name and use the&nbsp;SAVE&nbsp;button to add
					it to the program.
				</>
			),
			() => (
				<>
					The primary setting <strong>for your recording is the photo from your account.</strong> You can change it by
					clicking the edit wheel&nbsp;next to your recording.
				</>
			),
		],
	},
];

export default createHelp;
