import { AltText } from "@components/Layout";
import type landingEN from "@trans/en/landing";

const landing: typeof landingEN = {
	main: {
		header: () => (
			<span style={{ "font-weight": 400 }}>
				<b>více</b> než vaše audio aplikace
			</span>
		),
		playMe: "Přehraj mě",
		text: "voxtox je vaše první sociální audio platforma. Buďte sami sebou, vyjadřujte se. Buďte tvůrcem. Buďte posluchačem. Inspirujte a buďte inspirací. Svět kolem vás ve vašich uších",
		start: "Začněte zdarma",
		continue: (name) => `Pokračovat s ${name}`,
		email: "Registrace emailem",
		getApp: "STÁHNĚTE SI APLIKACI",
		try: "Vyzkoušejte si váš osobní voxtox!",
		open: "Otevřít v prohlížeči",
		free: "Zdarma!",
		getVoxApp: "Stáhněte si voxtox.fm aplikaci",
		scanQr: "Naskenujte tento QR kód a stáhněte si aplikaci",
		orAppStores: "Nebo si je prohlídněte na app obchodech",
	},
	listener: {
		header: "Být posluchačem",
		text: () => (
			<>
				Přestaňte rolovat. Jen poslouchejte.<AltText>Vše, CO POTŘEBUJETE</AltText>, v jedné aplikaci. Vytvořeno přímo
				pro vás. Autentické.voxtox'
			</>
		),
		subviews: {
			music: {
				header: "Hudba",
				text: "Vaše playlistové ráje: Miliony oblíbených melodií, snadné a zdarma! Poslouchejte na všech svých zařízeních.",
			},
			podcasts: {
				header: "Podcasty",
				text: "Skloubte tisíce oblíbených a exkluzivních epizod s vaší hudbou - Vítejte ve vaší osobní stanici!",
			},
			voxtalks: {
				header: "Voxtalks",
				text: "Vaše osobní zvuková galerie: Voxtalks přináší fascinující příběhy lidí, rodiny, přátel, celebrit a dalších - Nalaďte se a buďte pohnuti!",
			},
			news: {
				header: "Zprávy",
				text: "Prozkoumejte vybrané nebo sami vybrané příběhy - Buďte informováni, když vám to vyhovuje!",
			},
			communities: {
				header: "Komunity",
				text: "Buďte informováni o všem, co máte rádi! Všechny vaše komunity na jednom místě: přátelé a rodina, koníčky, práce, vaše bydliště a mnoho dalšího.",
			},
			radio: {
				header: "Radio",
				text: "Buďte informováni o všem, co máte rádi! Všechny vaše komunity na jednom místě: přátelé a rodina, koníčky, práce, vaše bydliště a mnoho dalšího.",
			},
		},
	},
	creator: {
		header: "Buďte tvůrcem",
		text: "Vytvářejte, sdílejte, inspirujte! Buďte architektem zvukového světa. Sdílejte své playlisty, programy, podcasty, rozhovory a mnoho dalšího. S několika kliknutími.",
		subviews: {
			music: {
				header: "Hudba",
				text: "Vaše osobní zvukové studio: Tvarujte své vlastní programy a playlisty - Sdílejte s celým světem nebo se připojte do skupin!",
			},
			voxtalks: {
				header: "Voxtalks",
				text: "Sdílejte své myšlenky, nápady a názory - Veřejně, soukromě, ve skupinách nebo ve svých vlastních pořadech! Připojte se k živým diskusím svým hlasem!",
			},
			voice: {
				header: "Vyjádřete své myšlenky",
				text: "Vyjadřujte svůj text: Přijměte sílu převodu textu na mluvené slovo - Dejte hlas svým nápadům a sdílejte! Mluvte, komentujte a mnohem víc!",
			},
			rss: {
				header: "Kouzlo RSS",
				text: "Sdílejte své zvukové tvorby se světem - Snadno přidejte svůj kanál do Voxtoxu!",
			},
		},
	},
	guide: {
		header: "Zvukové průvodce",
		create: "vytvořit průvodce",
		subviews: {
			main: {
				header: "Nechte své uši vést",
				text: "Objevujte snadno s pomocí zvuku. Propagujte své místo po celém světě pomocí zvukového průvodce pro každého. Zdarma.",
			},
			tourism: {
				header: "Povyšte turismus",
				text: "Vaše město, všechny jazyky: Uživatelsky přívětivá aplikace od voxtox umožňuje bezproblémově vytvářet zvukové prohlídky ve více jazycích!",
			},
			scan: {
				header: "Skenovat, poslouchat, objevovat",
				text: "Jeden QR kód - 30 jazyků! Není potřeba žádná aplikace, stačí foťák vašeho telefonu!",
			},
			voice: {
				header: "QR kódy mluví všemi jazyky",
				text: "Nahrajte svůj hlas, nahrávejte svůj zvuk nebo vytvářejte svůj zvukový průvodce s hlasovým botem pomocí umělé inteligence!",
			},
			geo: {
				header: "Geo-Push Magic",
				text: "Objevujte atrakce s upozorněními a zvukovými průvodci na mapě! Vede vás k personalizovaným zvukovým prohlídkám!",
			},
			camera: {
				header: "Vaše kamera, váš průvodce",
				text: "Plynulé zvukové prohlídky: Voxtox to zjednodušuje - Turisté si užívají v jejich jazyce jen pomocí foťáku svého telefonu!",
			},
		},
	},
	communities: {
		header: "Komunity",
		text: "Objevujte jednoduše s audiem. Propagujte svůj cíl po celém světě s audioprůvodcem pro každého. Zdarma.",
		subviews: {
			build: {
				header: "Vytvořte si svoji komunitu",
				text: "Je to místo, kde můžete vytvářet své vlastní skupiny, spojovat se s lidmi se stejnými zájmy a společně se rozvíjet v podpůrném a angažovaném prostředí.",
			},
			schools: {
				header: "Školy a vzdělávání",
				text: "Posilujeme školy vytvářením rozhlasových platforem pro studenty, aby mohli komunikovat a získávat základní informace zábavnou formou. Studenti se učí prostřednictvím zábavného obsahu a zůstávají propojeni se svou školní komunitou, podobně jako jejich rodiče.",
			},
			work: {
				header: "Práce a vysoké školy",
				text: "Posilte své týmy efektivním nástrojem pro komunikaci, šíření informací, interní podcasty, školení zaměstnanců a mnohem více. Zlepšete spolupráci a angažovanost na pracovišti s naší platformou.",
			},
			friends: {
				header: "Přátelé a rodina",
				text: "Vytvořte si osobní skupiny pro spojení s blízkými, sdílení životních okamžiků a zůstávání v kontaktu. Stejně tak se spojte s přáteli, sdílejte dobrodružství a společně si uchovávejte vzpomínky.",
			},
		},
	},
	advertising: {
		header: "Inzerce",
		text: "Vyzkoušejte efektivní reklamu založenou na datech na naší platformě. Přesně se zaměřte na své publikum pomocí geolokačního cílení, optimalizujte kampaně v reálném čase a užívejte si nákladově efektivní reklamu s transparentním reportováním a sníženým rizikem reklamních podvodů.",
	},
	footer: {
		header: "V případě dotazů nás kontaktujte",
		home: "Domů",
	},
};
export default landing;
