import { fetchApiResource } from "@app/utils/";
import type { AvailableLanguage } from "@trans/index";
import type { PreferenceTag } from "./types";

/**
 * fetch all preferences and their categories
 */
export const getPreferences = async (lang: AvailableLanguage) => {
	const res = await fetch(`/preferences/${lang}.json`);
	const json = await res.json();
	return json as Record<string, PreferenceTag[]>;
};
/**
 * patch user's preferences
 */
export const patchPreferences = (preferences: number[]) => {
	return fetchApiResource("user/preferences", "PATCH", preferences);
};

export const getGroupTags = async (lang: AvailableLanguage) => {
	const res = await fetch(`/group_tags/${lang}.json`);
	const json = await res.json();
	return json as Record<string, PreferenceTag[]>;
};
