import type docsEn from "@trans/en/docs";

const docs: typeof docsEn = {
	errorCode: (name: string) => (
		<>
			nezdařilo se vygenerovat kód pro <strong>{name}</strong>
		</>
	),
	print: "tisknout",
	source: "stáhnout zdrojové soubory",
};
export default docs;
