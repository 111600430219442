import { z } from "zod";

const envSchema = z.object({
	VITE_API_URI: z.string().url(),
	VITE_API_VERSION: z.string(),
	VITE_COMMENT_MAX_LENGTH: z.coerce.number(),
	VITE_DESCRIPTION_MAX_LENGTH: z.string(),
	VITE_FIREBASE_API_KEY: z.string(),
	VITE_FIREBASE_APP_ID: z.string(),
	VITE_FIREBASE_AUTH_DOMAIN: z.string(),
	VITE_FIREBASE_MESSAGING_SENDER_ID: z.string(),
	VITE_FIREBASE_PROJECT_ID: z.string(),
	VITE_FIREBASE_STORAGE_BUCKET: z.string(),
	VITE_FIREBASE_VAPID_KEY: z.string(),
	VITE_NSFW_FILTER: z.coerce.number(),
	VITE_TITLE_POSTFIX: z.string(),
	VITE_TTS_COMMENT_MAX_LENGTH: z.coerce.number().int().nonnegative(),
	VITE_TTS_COMMENT_MIN_LENGTH: z.coerce.number().int().nonnegative(),
	VITE_TTS_TRACK_MAX_LENGTH: z.coerce.number().int().nonnegative(),
	VITE_GOOGLE_PLAY: z.string().url(),
	VITE_APP_STORE: z.string().url(),
	VITE_DEV: z.coerce.boolean().optional(),
	VITE_MIXPANEL: z.string().optional(),
	VITE_SPECIAL_USERS: z
		.string()
		.optional()
		.transform((v) => (v ?? "").split(",")),
	VITE_GOOGLE_MAPS_API_KEY: z.string(),
	VITE_BIO_MAX_LENGTH: z.coerce.number(),
	VITE_MIN_GALLERY_IMAGE_SIZE: z.coerce.number(),
	VITE_DSN: z.string().optional(),
	VITE_MIN_CUT_DURATION: z.coerce.number().int().positive().default(480),
	VITE_MAX_GROUP_DESCRIPTION_LENGTH: z.coerce.number().int().default(1000),
});
export default envSchema;
