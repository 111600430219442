import { Row } from "@components/Layout";
import { styled } from "solid-styled-components";

export const CookiesRow = styled(Row)`
  position: fixed;
  bottom: 0;
  background: ${({ theme }) => theme?.colors.foreground};
  z-index: 3;
  @media only screen and (max-width: 756px) {
    & > div {
      width: 100%;
    }
    flex-direction: column;
  }
`;
