import { type FirebaseOptions, initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

import { deleteNotificationToken, postNotificationToken } from "@app/api/notifications";
import {
	type ApplicationVerifier,
	EmailAuthProvider,
	FacebookAuthProvider,
	GoogleAuthProvider,
	createUserWithEmailAndPassword,
	getAuth,
	reauthenticateWithCredential,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPhoneNumber,
	signInWithPopup,
	signOut,
	updatePassword,
} from "firebase/auth";
import {
	type DocumentChangeType,
	type DocumentData,
	type Query,
	type QueryDocumentSnapshot,
	getDocs,
	getFirestore,
	onSnapshot,
} from "firebase/firestore";
import type { z } from "zod";
import { setToStorage } from "..";

const firebaseConfig: FirebaseOptions = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const LOAD_LIMIT = 20;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const firestore = getFirestore(app);

export const messaging = (await isSupported()) ? getMessaging(app) : null;

/**
 * Signup user with password and email
 */
export const signup = (email: string, password: string) => createUserWithEmailAndPassword(auth, email, password);
/**
 * Logs in user with firebase
 */
export const login = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password);
/**
 * logs out firebase user
 */
export const logout = () => signOut(auth);
/**
 * Signup user using the Google provider
 */
export const singUpWithGoogle = async () => {
	const provider = new GoogleAuthProvider();
	provider.addScope("profile");
	provider.addScope("email");
	const result = await signInWithPopup(auth, provider);

	return result.user;
};
/**
 * Signup user using the Facebook provider
 */
export const signUpWithFacebook = async () => {
	const provider = new FacebookAuthProvider();
	provider.addScope("email");
	provider.addScope("public_profile");
	const result = await signInWithPopup(auth, provider);

	return result.user;
};
/**
 * Sends an email with link for resetting passwords
 * @param email
 */
export const resetPassword = (email: string) => sendPasswordResetEmail(auth, email);

const getDeviceNotificationToken = async () => {
	let token = "";
	if (messaging)
		token = await getToken(messaging, {
			vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
		});
	return token;
};

/**
 * Subscribe user's device to receive desktop notifications by sending their token
 */
export const registerNotifications = async () => {
	if (Notification) {
		setToStorage("true", "blockNotifications", "user");
		const res = await Notification.requestPermission();
		if (res === "granted") {
			const token = await getDeviceNotificationToken();
			setToStorage(token, "deviceToken", "user");
			return postNotificationToken(token).then(() => setToStorage(null, "notificationsRevoked", "user"));
		}
	}
};
/**
 * Unsubscribe user's device from receiving desktop notifications by sending their token
 */
export const unregisterNotifications = async () => {
	const token = await getDeviceNotificationToken();
	return deleteNotificationToken(token)
		.then(() => setToStorage("true", "notificationsRevoked", "user"))
		.then(() => setToStorage(null, "deviceToken", "user"));
};

/**
 * Re-authenticate used and change their password
 */
export const changePassword = async (currentPassword: string, newPassword: string) => {
	if (auth.currentUser?.email) {
		const cred = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
		await reauthenticateWithCredential(auth.currentUser, cred);
		return updatePassword(auth.currentUser, newPassword);
	}
};
/**
 * Signup user using the Phone provider
 * @param phone User's phone number
 */
export const singInWithPhone = (phone: string) => {
	signInWithPhoneNumber(auth, phone, window.recaptchaVerifier as ApplicationVerifier).then((res) => {
		window.confirmationResult = res;
	});
};

export const fetchFirestoreQuery = async <T extends z.ZodSchema>(
	q: { query: Query<DocumentData>; validator: T },
	singleObject?: boolean,
) => {
	const querySnapshot = await getDocs(q.query);
	const response = querySnapshot.docs.map((item) => ({ ...item.data(), uuid: item.id }));

	const data = q.validator.parse(singleObject ? response[0] : response) as z.infer<typeof q.validator>;

	return {
		data,
		lastItem: querySnapshot.docs[querySnapshot.docs.length - 1],
	};
};

export const useListenFirestore = <T extends z.ZodSchema>(
	q: { query: Query<DocumentData>; validator: T },
	onChange: (data: z.TypeOf<T>, lastItem: QueryDocumentSnapshot<DocumentData, DocumentData>) => void,
	filter?: DocumentChangeType,
) =>
	onSnapshot(q.query, (snapshot) => {
		const res = snapshot
			.docChanges()
			.filter((change) => (filter ? change.type === filter : true))
			.map((item) => ({ uuid: item.doc.id, ...item.doc.data(), changeType: item.type }));

		const data = q.validator.parse(res) as z.infer<typeof q.validator>;
		onChange(data, snapshot.docs[snapshot.docs.length - 1]);
	});
