import cs from "./cs";
import en from "./en";

export const availableLanguages = ["en", "cs"] as const;
export type AvailableLanguage = (typeof availableLanguages)[number];

export const dictionaries = {
	en,
	cs,
} as const;
