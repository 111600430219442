import createHelp from "@trans/en/app/createHelp";
import createSoundEffects from "@trans/en/app/createSoundEffects";
import { pt } from "@trans/utils";
import firebaseContent from "./app/firebaseNotificationsContent";
import content from "./app/notificationsContent";

const app = {
	nav: {
		home: "feed",
		myProgrammes: "my programmes",
		bookmarks: "bookmarks",
		history: "my history",
		settings: "settings",
		logout: "logout",
		back: "back",
	},
	myvoxers: {
		header: "my voxers",
	},
	discover: {
		header: "discover",
		navbar: {
			tracklist: "tracklist",
			voxers: "voxers",
			podcasts: "podcasts",
			radio: "radio",
		},
		voxers: {
			unfollow: "unfollow",
			follow: "follow",
		},
	},
	notifications: {
		content,
		leaveGroup: "Leave group",
		joinGroup: "Join group",
		show: "Show",
		readAll: "Read all",
		header: "notifications",
		firebaseContent,
		allow: "Allow notifications?",
		disable: "Disable notifications",
	},
	groups: {
		header: "groups",
		createdBy: "created by",
		top: "top voxers",
		noTop: "This group does not have any programmes to display top voxers",
		add: "add group",
		addHeader: "add new group",
		addDescription: "add new group and invite members",
		name: "group name",
		visibility: {
			header: "visibility",
			public: "public",
			private: "private",
		},
		addMembers: "add members",
		toBeInvited: (count: number) =>
			pt(
				{
					zero: "no voxers are going to be invited",
					one: "1 voxer is going to be invited",
					other: `${count} voxers are going to be invited`,
				},
				count,
			),
		search: "search in group",
		memberName: "voxer name",
		memberList: "member list",
		removeMember: "remove member from group?",
		removing: {
			loading: "removing",
			success: (user: string) => `user ${user} has been removed`,
			error: (user: string) => `user ${user} could not be removed`,
		},
		invite: "invite",
		invited: "invited",
		inviting: {
			loading: "sending invitations",
			success: "invitations sent",
			error: "could not send invitations",
		},
		joining: {
			loading: "joining",
			success: "joined",
			error: "could not join",
		},
		leaving: {
			loading: "leaving group",
			success: "group left",
			error: "could not leave group",
		},
		creating: {
			loading: "creating new group",
			success: (name: string) => `group ${name} has been created`,
			error: "could not create group",
		},
		delete: "delete group?",
		addProgramme: "create programme",
		members: (count: number) => `${count} member${pt({ other: "s" }, count, "en")}`,
		addAdmins: "add admins",
		addMods: "add moderators",
		photo: "add group photo",
		description: "group description",
		website: "website link",
		tags: {
			select: "select up to 5 tags",
			music: "music tags",
			search: "search tags",
		},
		edit: "edit group",
		noDescription: "group has no description",
		privacy: "group privacy",
		admins: "administrators",
		moderators: "moderators",
		about: "about group",
		manageMembers: "manage members",
		manageMember: "manage member",
		manage: "manage",
		makeAdmin: "make admin",
		makeModerator: "make moderator",
		makeUser: "make basic user",
		deleteMember: "remove member from group",
		searchUsers: "search members",
		noPostPerm: "you do not have permissions to crate new programmes in this group",
	},
	history: {
		header: "history",
	},
	programme: {
		report: "report",
		share: "share",
		unshare: "un-share",
		tracks: (count: number) => `${count} track${pt({ other: "s" }, count)}`,
		sharing: {
			loading: "sharing",
			success: "shared",
			error: "could not share",
		},
		reporting: {
			success: "reported",
		},
		comments: {
			header: "comments",
			add: "add comment",
		},
		programmes: "programmes",
		delete: "delete programme?",
		notFound: "programme does not exist",
		noProgrammes: "no programmes yet",
		addNew: "add your programme now",
		play: "play programme",
		pause: "pause programme",
		signIn: "sign in to voxtox",
		list: "programme list",
	},
	settings: {
		header: "settings",
		save: "save",
		menu: {
			preferences: "preferences",
			signature: "signature",
			password: "password",
			notifications: "notifications",
			privacy: "privacy & security",
			language: "language",
			rss: "import feed",
			theme: "theme",
		},
		password: {
			header: "password",
			currentPassword: "current password",
			newPassword: "new password",
			confirmPassword: "enter password again",
			success: "password successfully changed",
			loading: "changing password",
		},
		theme: {
			lightTheme: "light theme",
			darkTheme: "dark theme",
		},
		notifications: {
			loading: "updating notification settings",
			success: "successfully updated",
		},
		privacy: {
			header: "who can see my content?",
			loading: "updating",
			success: "successfully updated",
			everybody: "everybody",
			onlyMe: "only me",
			open: "otevřít",
			dateOfRegister: "registration date",
			privacyDoc: "personal data & privacy",
			terms: "terms of service",
			deleteProf: {
				title: "delete profile",
				loading: "deleting profile",
				success: "check your email for a confirmation link",
			},
			delConfirm: {
				error: "could not delete profile",
				loading: "deleting profile",
				success: "profile has been deleted",
			},
		},
		rss: {
			import: (date: string) => `last imported at: ${date}`,
			never: "never",
			timing: "importing happens once a day",
			loading: "uploading rss",
			success: "uploaded",
			error: "invalid value",
		},
		signature: {
			uploadFromDevice: "upload from device",
			click: "click to record signature",
			del: "delete signature",
		},
		preferences: {
			loading: "saving preferences",
			success: "preferences saved",
		},
	},
	search: {
		voxtox: "search voxtox",
		noTracks: "no tracks found",
		noGroups: "no groups found",
	},
	users: {
		header: "users",
	},
	create: {
		header: "create",
		help: createHelp,
		storage: {
			header: "storage",
			empty: "directory is empty",
		},
		drafts: {
			header: "drafts",
		},
		track: {
			music: "music",
			search: "search",
			upload: "upload",
			record: "record",
			edit: {
				name: "name",
				image: "image",
				private: "lock sharing",
			},
			clickToRecord: "click to record new track",
			uploading: "uploading",
			create: {
				converting: "your track is being converted",
				noCut: "do not cut",
			},
			added: (name: string) => (
				<>
					track <strong>{name}</strong> has been added
				</>
			),
		},
		publish: {
			header: "save programme",
			selectImage: "select an image",
			categories: "categories",
			location: "location",
			publishAt: "publish at",
			total: "total",
			noGroup: "no group",
			publish: "publish",
			done: "done",
		},
		editor: {
			play: "play",
			pause: "pause",
			stop: "stop",
			copy: "copy",
			cut: "cut",
			record: "record your voice",
			soundEffects: "sound effects",
			voiceEffects: "voice effects",
			upload: "upload & record",
		},
		soundEffects: createSoundEffects,
		cutTrack: "cut track to pieces",
	},
	profile: {
		header: "profile",
		gonnaBePub: "to be published on:",
		pubNow: "publish now",
		userNotFound: "This user is either private or does not exist",
		following: "following",
		followers: "followers",
		map: "map",
		generateQR: "my QR",
		live: "live",
		show: "show profile",
		findMore: "find more voxers",
		editProfile: "edit profile",
		search: "search voxer's content",
		block: "block",
		unblock: "unblock",
		followingOther: "following",
	},
	editProfile: {
		categories: "categories",
		gender: "gender",
		workingAs: "working as",
		city: "city",
		interestedIn: "interested in",
		inspiredBy: "inspired by",
		tts: "text to speech",
		chooseVoice: "choose voice",
		birthday: "birthday",
		editLanguages: "edit languages",
		selectCategory: "select category",
		selectGender: "select gender",
		gallery: "gallery",
	},
	tts: {
		advanced: "advanced mode",
		limit: (used: number, max: number) => `used ${used} out of ${max} characters`,
		preview: "preview your text",
		error: "could not preview your text",
		simple: "simple mode",
	},
	error: {
		header: "Something went wrong and we are working on it!",
		youCan: "You can try to recover from the error and if you end up here again, you can go back home",
		recover: "try to recover",
		home: "go back home",
	},
	comment: {
		voice: "record my voice",
		tts: "text to speech",
		click: "click to record your comment",
		delete: "delete comment?",
		comment: "comment",
	},
	location: {
		you: "your location",
		back: "back to my position",
		address: "type address",
	},
	chat: {
		header: "chat",
		newConversation: "new conversation",
		voiceMessage: "voice message",
		emptySearchUsers: "no voxers were found",
		viewed: "viewed",
		uploadVoiceMessage: "upload voice message",
	},
};
export default app;
