import Button from "@components/Button";
import type { NotificationPayload } from "@hooks/useFirebaseNotifications/types";
import { A } from "@solidjs/router";

const content = (n: NotificationPayload) => {
	switch (n.type) {
		case "conversation.message.create":
			return (
				<>
					User <span>{n.sender_nickname}</span> send you a message
					<br />
					<A href="/chat">
						<Button h="45px">chat</Button>
					</A>
				</>
			);
		case "group_invite.create":
			return (
				<>
					You have been invited to join <span>{n.group_name}</span>
				</>
			);
		case "group_member.kick":
			return (
				<>
					You have been kicked out of <span>{n.group_name}</span>
				</>
			);
		case "group_member.leave":
			return (
				<>
					Member has left your group <span>{n.group_name}</span>
				</>
			);
		case "tracklist.create":
			return (
				<>
					User <span>{n.author_nickname}</span> has created <span>{n.tracklist_name}</span>
				</>
			);
		case "tracklist_comment.create":
			return (
				<>
					User <span>{n.sender_nickname}</span> has left a comment on <span>{n.tracklist_name}</span>
				</>
			);
		case "tracklist_like.create":
			return (
				<>
					<>
						User <span>{n.user_nickname}</span> has left a like on
						<span>{n.tracklist_name}</span>
					</>
				</>
			);
		case "user_follow.create":
			return (
				<>
					User <span>{n.user_nickname}</span> has started following you
				</>
			);
	}
};
export default content;
