import { styled } from "solid-styled-components";

export const CodeBlock = styled.code`
  opacity: 0.6;
  padding: 20px;
  ${({ theme }) => `
        background: ${theme?.input?.background};
        color: ${theme?.input?.color};
    `}
  &:hover {
    opacity: 1;
  }
`;
