import type { UserNotification } from "@api/notifications/types";

const content = (n: UserNotification) => {
	switch (n.type) {
		case "conversation.message.create":
			return (
				<>
					User <span>{n.triggeredBy?.nickname}</span> send you a message
				</>
			);
		case "group_invite.create":
			return (
				<>
					You have been invited to join <span>{n.ref.name}</span>
				</>
			);
		case "group_member.kick":
			return (
				<>
					You have been kicked out of <span>{n.ref.name}</span>
				</>
			);
		case "group_member.leave":
			return (
				<>
					Member has left your group <span>{n.ref.name}</span>
				</>
			);
		case "tracklist.create":
			return (
				<>
					User <span>{n.triggeredBy?.nickname}</span> has created <span>{n.ref.name}</span>
				</>
			);
		case "tracklist_comment.create":
			return (
				<>
					User <span>{n.triggeredBy?.nickname}</span> has left a comment on <span>{n.ref.name}</span>
				</>
			);
		case "tracklist_like.create":
			return (
				<>
					<>
						User <span>{n.triggeredBy?.nickname}</span> has left a like on
						<span>{n.ref.name}</span>
					</>
				</>
			);
		case "user_follow.create":
			return (
				<>
					User <span>{n.triggeredBy?.nickname}</span> has started following you
				</>
			);
	}
};
export default content;
