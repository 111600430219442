import { z } from "zod";

export enum TrackPlatforms {
	VOXTOX = "voxtox",
	EXTERNAL = "external",
	UNIVERSE = "universe",
}
export enum TrackType {
	RECORD = "record",
	TTS = "tts",
	MUSIC = "music",
	UPLOAD = "upload",
}

export const rawTrackSchema = z.object({
	id: z.string(),
	name: z.string(),
	length: z.number().int(),
	author: z.object({
		photo: z.string().url().catch("/logo_no_text.png"),
		uid: z.string().nullable(),
		nickname: z.string(),
	}),
	bookmarked: z.boolean(),
	platform: z.nativeEnum(TrackPlatforms),
	photo: z.string().url().catch("/logo_no_text.png"),
	type: z.nativeEnum(TrackType),
	private: z.boolean(),
});

export const trackSchema = rawTrackSchema.transform((t) => {
	t.photo = t.type === TrackType.RECORD && t.photo === "/logo_no_text.png" ? t.author.photo : t.photo;
	return t;
});
