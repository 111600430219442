const global = {
	or: "or",
	error: "Something went wrong.",
	loading: {
		loading: "Loading",
		success: "Finished loading",
		error: "Loading has failed",
	},
	seeAll: "see all",
	delete: "delete",
	close: "close",
	edit: "edit",
	copied: "copied to clipboard",
	playAll: "play all",
	saving: {
		loading: "saving",
		success: "saved",
		error: "could not save",
	},
	deleting: {
		loading: "deleting",
		success: "deleted",
		error: "could not delete",
	},
	uploading: {
		loading: "uploading",
		success: "uploaded",
		error: "could not upload",
	},
	download: "download",
	continue: "continue",
	cancel: "cancel",
	appDownload: {
		header: " download voxtox app",
		sub: "enjoy music, podcasts and more",
	},
	cookies: {
		text: [
			"This website uses and shares information about end users, including unique identifiers (such as cookies, IP addresses and e-mails) that enable user identification. ",
			"By using this page you confirm your consent to the access and processing of the personal data specified above and its possible sharing with our selected partners (including analytical tools).",
		],
		allow: "Agree",
		more: "find out more",
	},
	yes: "yes",
	no: "no",
	link: "link",
};
export default global;
