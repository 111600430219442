import Button from "@components/Button";
import type { NotificationPayload } from "@hooks/useFirebaseNotifications/types";
import { A } from "@solidjs/router";

const content = (n: NotificationPayload) => {
	switch (n.type) {
		case "conversation.message.create":
			return (
				<>
					Uživatel <span>{n.sender_nickname}</span> vám poslal zprávu
					<br />
					<A href="/chat">
						<Button h="45px">chat</Button>
					</A>
				</>
			);
		case "group_invite.create":
			return (
				<>
					Byly jste pozvání do <span>{n.group_name}</span>
				</>
			);
		case "group_member.kick":
			return (
				<>
					Byly jste odstraněni z <span>{n.group_name}</span>
				</>
			);
		case "group_member.leave":
			return (
				<>
					Uživatel opustil <span>{n.group_name}</span>
				</>
			);
		case "tracklist.create":
			return (
				<>
					Uživatel <span>{n.author_nickname}</span> vytvořil program <span>{n.tracklist_name}</span>
				</>
			);
		case "tracklist_comment.create":
			return (
				<>
					Uživatel <span>{n.sender_nickname}</span> vytvořil komentář u programu
					<span>{n.tracklist_name}</span>
				</>
			);
		case "tracklist_like.create":
			return (
				<>
					<>
						Uživatel <span>{n.user_nickname}</span> dal like programu
						<span>{n.tracklist_name}</span>
					</>
				</>
			);
		case "user_follow.create":
			return (
				<>
					Uživatel <span>{n.user_nickname}</span> vás začal sledovat
				</>
			);
	}
};
export default content;
