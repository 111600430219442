import { ApiError as EApiError } from "@src/app/types/api";
import { parsePhoneNumber } from "libphonenumber-js";
import { z } from "zod";

export const emailSchema = z.string().email("errors.email");

export const passwordSchema = z.string().min(5, "errors.min");

export const phoneSchema = z.string().refine((v) => {
	try {
		const phoneNumber = parsePhoneNumber(v);
		return phoneNumber?.isValid();
	} catch {
		return false;
	}
}, "errors.phone");

export const apiErrorSchema = z.object({
	statusCode: z.number(),
	message: z.nativeEnum(EApiError).catch(EApiError.GENERIC),
	error: z.string(),
});

export type ApiError = z.infer<typeof apiErrorSchema> | undefined;
