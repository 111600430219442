import { groupSchema } from "@api/groups/schemas";
import { tracklistSchema } from "@api/tracklists/schemas";
import { userSchema } from "@api/user/schemas";
import { z } from "zod";

const notificationTriggeredBySchema = z.lazy(() => userSchema.pick({ uid: true, nickname: true, photo: true }));

export const userNotificationSchema = z
	.discriminatedUnion("type", [
		z.object({
			type: z.literal("tracklist.create"),
			ref: z.lazy(() => tracklistSchema.pick({ name: true, uuid: true })),
		}),
		z.object({
			type: z.literal("tracklist_comment.create"),
			ref: z.lazy(() => tracklistSchema.pick({ name: true, uuid: true })),
		}),
		z.object({
			type: z.literal("group_invite.create"),
			ref: z.lazy(() =>
				groupSchema.pick({ name: true, uuid: true, joined: true }).extend({
					invite_active: z
						.object({
							created_at: z.coerce.date(),
						})
						.optional()
						.nullable()
						.or(z.boolean()),
				}),
			),
		}),
		z.object({
			type: z.literal("user_follow.create"),
			ref: z.lazy(() => userSchema.pick({ nickname: true, uid: true, photo: true })),
		}),
		z.object({
			type: z.literal("tracklist_like.create"),
			ref: z.lazy(() => tracklistSchema.pick({ name: true, uuid: true })),
		}),
		z.object({
			type: z.literal("group_member.kick"),
			ref: z.lazy(() => groupSchema.pick({ name: true, uuid: true })),
		}),
		z.object({
			type: z.literal("group_member.leave"),
			ref: z.lazy(() => groupSchema.pick({ name: true, uuid: true })),
		}),
		z.object({
			type: z.literal("conversation.message.create"),
			ref: z.lazy(() => userSchema.pick({ nickname: true, uid: true, photo: true })),
		}),
	])
	.and(
		z.object({
			id: z.number(),
			created_at: z.coerce.date(),
			triggeredBy: notificationTriggeredBySchema.nullable(),
			read_at: z.coerce.date().nullable(),
			viewed_at: z.coerce.date().nullable(),
		}),
	);

export const userNotificationsHistorySchema = z.object({
	data: z.array(userNotificationSchema),
	metadata: z.object({
		num_unread: z.number().int().nonnegative(),
	}),
});
