import { getUserLocale } from "@contexts/I18nProvider/utils";
import { type AvailableLanguage, availableLanguages } from ".";

export const isLocale = (locale: string): locale is AvailableLanguage =>
	availableLanguages.includes(locale as AvailableLanguage);

export const pt = (
	values: Partial<Record<Intl.LDMLPluralRule, string>>,
	count: number,
	language?: AvailableLanguage,
) => {
	return values[new Intl.PluralRules(language ?? getUserLocale()).select(count)] ?? "";
};
