import { styled } from "solid-styled-components";

export const AppWrapper = styled.div`
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  color: ${({ theme }) => theme?.colors.color};
`;

export const ColoredCircle = styled.div<{
	backgroundColor: string;
	textColor?: string;
	diameter: number;
	textSize?: string;
	top?: number;
	left?: number;
	right?: number;
	bottom?: number;
	hover?: boolean;
	padding?: string;
	svgLeft?: number;
	svgTop?: number;
	boxShadow?: string;
	overflow?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition:
    width 0.3s,
    height 0.3s,
    background-color 0.3s;
  flex-shrink: 0;
  ${(props) => `
		width: ${props.diameter}px;
		height: ${props.diameter}px;
		${props.textColor ? `* { color:${props.textColor} !important;} color: ${props.textColor} !important;` : ""}
		${props.textSize ? `font-size:${props.textSize};` : ""}
		background-color: ${props.backgroundColor};
		${props.top || props.left || props.right ? "position:absolute;" : "position:relative;"}
		${props.top ? `top:${props.top}px;` : ""}
		${props.left ? `left:${props.left}px;` : ""}
		${props.right ? `right:${props.right}px;` : ""}
		${props.bottom ? `bottom:${props.bottom}px;` : ""}
    padding: ${props.padding ?? "10px"};
    overflow: ${props.overflow ?? "hidden"};
    ${props.boxShadow ? `box-shadow: ${props.boxShadow};` : ""}
		${
			props.hover
				? `
					&:hover{
						width: ${props.diameter + 10}px;
						height: ${props.diameter + 10}px;
					}
					`
				: ""
		}
  `}
  svg {
    position: relative;
    ${(props) => `
		  ${props.svgLeft ? `left:${props.svgLeft}px;` : ""}
		  ${props.svgTop ? `top:${props.svgTop}px;` : ""}
    `}
  }
`;
