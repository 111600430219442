import Button from "@components/Button";
import { Column, Row } from "@components/Layout";
import useI18n from "@contexts/I18nProvider/hooks/useI18n";
import { A, useLocation } from "@solidjs/router";
import { getFromStorage, setToStorage } from "@src/app/utils";
import { Show, type VoidComponent, createSignal } from "solid-js";
import { CookiesRow } from "./styled";

const Cookies: VoidComponent = () => {
	const location = useLocation();
	const [cookies, setCookies] = createSignal(
		location.pathname.includes("embed") ? true : getFromStorage("cookies", "user"),
	);
	const handleCookies = () => {
		setCookies("true");
		setToStorage("true", "cookies", "user");
	};
	const { t } = useI18n();

	return (
		<Show when={!cookies()}>
			<CookiesRow justifyContent="space-between" padding="15px" alignItems="stretch">
				<Column>
					<Row flexWrap="wrap" padding="10px 0" gap="10px">
						{t("global.cookies.text")[0]} <br />
						{t("global.cookies.text")[1]} <br />
					</Row>
					<Row padding="10px 0 0">
						<A href="/docs/cookies">
							<Button>{t("global.cookies.more")}</Button>
						</A>
					</Row>
				</Column>
				<Column alignItems="flex-end" justifyContent="flex-end" w="max-content">
					<Button onClick={handleCookies}>{t("global.cookies.allow")}</Button>
				</Column>
			</CookiesRow>
		</Show>
	);
};
export default Cookies;
