import type { DefaultTheme } from "solid-styled-components";

/**
 * Settings of the light theme
 */
const lightTheme: DefaultTheme = {
	colors: {
		background: "#F6F8FC",
		foreground: "#FFF",
		color: "#1a1b41",
		colorInvert: "#FFF",
		accentPrimary: "#1FD1E5",
		accentSecondary: "#003F91",
		accentTertiary: "#5A5A5C",
		accentQuinary: "#EDFBFD",
		error: "#FF6900",
		normal: "#7783F6",
		success: "#7783f6",
		createGray: "#c1c1c2",
		chatGray: "#e3e3e3",
	},
	shadows: {
		small: "0 0 5px rgba(0,0,0,.1)",
		medium: "0 0 15px rgba(0,0,0,.1)",
		big: "0 0 30px rgba(0,0,0,.1)",
	},
	input: {
		background: "#F6F8FC",
		color: "#1A1B41",
	},
	button: {
		background: "#1FD1E5",
		color: "#FFF",
	},
	loader: {
		dark: "#dbdbdb",
		light: "#f7f7f7",
	},
	mobilePlayer: {
		background: "#f2f2f2",
	},
	notifications: {
		"conversation.message.create": "#bffc64",
		"group_invite.create": "#FF6AEF",
		"group_member.kick": "#FF6900",
		"group_member.leave": "#fc3a3a",
		"tracklist.create": "#1fd0e4",
		"tracklist_comment.create": "#7DFF6A",
		"tracklist_like.create": "#6AFFD5",
		"user_follow.create": "#D9C417",
	},
	tracklist: {
		discoverShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
		trackEven: "#eef0f4",
		activeShadow: "#1fd0e4 0px 0px 10px",
		dataBadge: "#F6F8FC",
		shareShadow: "rgba(115, 115, 115, 0.26) 0px 0px 5px 2px",
		liveShadow: "rgb(255, 105, 0) 0px 0px 10px 2px",
		list: "#f3f3f3",
	},
	downloadApp: "#d2f6fa",
	landing: {
		tab: {
			altBackground: "#d9d9d9",
			background: "#f8f8f8",
		},
	},
	editor: {
		background: "#000",
		foreground: "#A3A3A3",
		accentPrimary: "#171717",
		accentSecondary: "#262626",
		accentTertiary: "#3B82F6",
		delete: "#ff7575",
	},
	embed: {
		background: "#efeff1",
		activeTrack: "#e1e1e3",
		altText: "#5A5A5C",
		close: "#b7b7b7",
	},
};
/**
 * Settings of the dark theme
 */
const darkTheme: DefaultTheme = {
	colors: {
		background: "#000",
		foreground: "#141414",
		color: "#1FD0E4",
		colorInvert: "#141414",
		accentPrimary: "#1fd0e4",
		accentSecondary: "#999EA6",
		accentTertiary: "#1FD0E4",
		accentQuinary: "#b0b1d00d",
		error: "#FF6900",
		normal: "#7783F6",
		success: "#52547d",
		createGray: "#1fd0e4",
		chatGray: "#2d2d2d",
	},
	shadows: {
		small: "0 0 5px rgba(0,0,0,.1)",
		medium: "0 0 15px rgba(0,0,0,.1)",
		big: "0 0 30px rgba(0,0,0,.1)",
	},
	input: {
		background: "#0f0f0f",
		color: "#fff",
	},
	button: {
		background: "#1fd0e4",
		color: "#fff",
	},
	loader: {
		dark: "black",
		light: "#333333",
	},
	mobilePlayer: {
		background: "#f2f2f2",
	},
	notifications: {
		"conversation.message.create": "#bffc64",
		"group_invite.create": "#FF6AEF",
		"group_member.kick": "#FF6900",
		"group_member.leave": "#fc3a3a",
		"tracklist.create": "#1fd0e4",
		"tracklist_comment.create": "#7DFF6A",
		"tracklist_like.create": "#6AFFD5",
		"user_follow.create": "#D9C417",
	},
	tracklist: {
		discoverShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.15)",
		trackEven: "#2f2f2f",
		activeShadow: "#1fd0e4 0px 0px 10px",
		dataBadge: "#3d3d3d",
		shareShadow: "rgba(115, 115, 115, 0.26) 0px 0px 5px 2px",
		liveShadow: "rgb(255, 105, 0) 0px 0px 10px 2px",
		list: "#f3f3f3",
	},
	downloadApp: "#141414",
	landing: {
		tab: {
			altBackground: "#d9d9d9",
			background: "#f8f8f8",
		},
	},
	editor: {
		background: "#000",
		foreground: "#A3A3A3",
		accentPrimary: "#171717",
		accentSecondary: "#262626",
		accentTertiary: "#3B82F6",
		delete: "#ff7575",
	},
	embed: {
		background: "#efeff1",
		activeTrack: "#e1e1e3",
		altText: "#5A5A5C",
		close: "#b7b7b7",
	},
};
/**
 * Combining al themes together so they can be used in the ThemeContext and propagated through the whole app
 */
export const appThemes = {
	lightTheme: lightTheme,
	darkTheme: { ...lightTheme, ...darkTheme },
};
